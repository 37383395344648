// Librairies
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { faDownload, faPen, faPlus, faTrash, faUpload, faUserEdit, faUserMinus, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { union, point, polygon, lineString, booleanIntersects, combine, centroid } from '@turf/turf';
import i18n from '../locales/i18n';
// Services
import ProjectsService from '../services/ProjectsService';
// Utils
import TreesUtil from './TreesUtil';
import GreenSpacesUtil from './GreenSpacesUtil';
import FurnituresUtil from './FurnituresUtil';
import FormattersUtil from './FormattersUtil';
import { showToast } from './ToastsUtil';
import WebSocketUtil from './WebSocketUtil';
import GeometriesUtil from './GeometriesUtil';
import RightsUtil from './RightsUtil';

export default class ProjectsUtil {
    static getRequiredFields() {
        return {
            trees: TreesUtil.getFields(false),
            greenSpaces: GreenSpacesUtil.getFields(),
            furnitures: FurnituresUtil.getFields()
        }
    }

    static getProjectRequiredFields(project, projectId, organization) {
        const projectSubscription = project.organization?.subscription || organization;
        let requiredFields = this.getRequiredFields();

        if (!project) return requiredFields;
        else if (project.requiredFields) {
            let projectRequiredFields = {};
            if (Array.isArray(project.requiredFields)) {
                if (projectId) projectRequiredFields = JSON.parse(project.requiredFields.find(rf => JSON.parse(rf).projectId === projectId));
                else project.requiredFields.forEach(rf => projectRequiredFields = { ...projectRequiredFields, ...JSON.parse(rf) });
            } else if (project.requiredFields.constructor === ({}).constructor) projectRequiredFields = project.requiredFields;
            else projectRequiredFields = JSON.parse(project.requiredFields);

            let rf = {
                trees: { ...requiredFields.trees, ...projectRequiredFields?.trees },
                greenSpaces: { ...requiredFields.greenSpaces, ...projectRequiredFields?.greenSpaces },
                furnitures: { ...requiredFields.furnitures, ...projectRequiredFields?.furnitures }
            };

            if (!projectSubscription?.expertMode)
                ['root', 'collar', 'trunk', 'branch', 'leaf'].forEach(organ => {
                    ['Symptoms', 'Pathogens', 'Pests', 'Epiphytes'].forEach(property => {
                        rf.trees[`${organ}${property}`] = false;
                    });
                });

            if (project.type === 'project') project.requiredFields = JSON.stringify(rf);
            return rf;
        } else { // Si le projet n'a pas de requiredFields, on les ajoute
            project.requiredFields = JSON.stringify(requiredFields);
            return requiredFields;
        }
    }

    static getPublicFields() {
        return {
            trees: TreesUtil.getFields(true),
            greenSpaces: GreenSpacesUtil.getFields(),
            furnitures: FurnituresUtil.getFields(),
            main: {
                images: true,
                trees: true,
                greenSpaces: true,
                furnitures: true,
                markers: true,
                stations: true,
                references: true,
                actions: true,
                photos: true,
                files: true,
                filters: true,
                statistics: true,
                charts: true,
                dataTable: true,
                thematicMaps: true
            }
        }
    }

    static getProjectPublicFields(project, projectCollaborators = []) {
        projectCollaborators = projectCollaborators || [];
        const isUserInProject = projectCollaborators.length ? this.isUserInProject(projectCollaborators) : false;
        let publicFields = this.getPublicFields();

        if (!project) return publicFields;
        else if (project.publicFields) {
            let projectPublicFields = {};
            if (project.publicFields.constructor === ({}).constructor) projectPublicFields = project.publicFields;
            else projectPublicFields = JSON.parse(project.publicFields);
            let pf = {
                main: { ...publicFields.main, ...projectPublicFields?.main },
                trees: { ...publicFields.trees, ...projectPublicFields?.trees },
                greenSpaces: { ...publicFields.greenSpaces, ...projectPublicFields?.greenSpaces },
                furnitures: { ...publicFields.furnitures, ...projectPublicFields?.furnitures }
            };

            project.publicFields = JSON.stringify(pf);
            if (isUserInProject) {
                const userId = jwt_decode(new Cookies().get('token')).id;
                const projectCollaborator = projectCollaborators.find(pc => pc.userId === userId);
                publicFields.main.trees = RightsUtil.canRead(projectCollaborator.projectRole.trees);
                publicFields.main.greenSpaces = RightsUtil.canRead(projectCollaborator.projectRole.greenSpaces);
                publicFields.main.furnitures = RightsUtil.canRead(projectCollaborator.projectRole.furnitures);
                publicFields.main.markers = RightsUtil.canRead(projectCollaborator.projectRole.markers);
                publicFields.main.stations = RightsUtil.canRead(projectCollaborator.projectRole.stations);
                publicFields.main.actions = RightsUtil.canRead(projectCollaborator.projectRole.actions);
                publicFields.main.charts = RightsUtil.canRead(projectCollaborator.projectRole.charts);
                publicFields.main.dataTable = RightsUtil.canRead(projectCollaborator.projectRole.tables);
                publicFields.main.filters = RightsUtil.canRead(projectCollaborator.projectRole.filters);
                publicFields.main.statistics = RightsUtil.canRead(projectCollaborator.projectRole.statistics);
                publicFields.main.thematicMaps = RightsUtil.canRead(projectCollaborator.projectRole.thematicMaps);
                return publicFields;
            }

            return pf;
        } else { // Si le projet n'a pas de publicFields, on les ajoute
            project.publicFields = JSON.stringify(publicFields);
            return publicFields;
        }
    }

    static isUserInProject(userBaseProjects) {
        // Carte publique
        if (!userBaseProjects) return true;
        // Utilisateur non connecté accèdant au projet
        const cookie = new Cookies().get('token');
        if (!cookie) return false;
        const userId = jwt_decode(cookie).id;
        if (!userId) return false;
        // Utilisateur connecté accèdant au projet
        const userBaseProject = userBaseProjects.find(x => x.userId === userId);
        return userBaseProject ? true : false;
    }

    static assignProjectFormulaVersionsType(project, formulas) {
        if (project?.projectFormulaVersions) {
            project.projectFormulaVersions.forEach(pfv => {
                const formula = formulas.find(formula => (
                    formula.formulaVersions.find(formulaVersion => formulaVersion.id === pfv.formulaVersionId)
                ));
                pfv.formulaId = formula?.id;
                if (pfv.formulaId === 4) {
                    const formulaVersion = formula.formulaVersions.find(formulaVersion => formulaVersion.id === pfv.formulaVersionId);
                    pfv.formulaType = formulaVersion.label.toLowerCase().includes('wallonie') ? 'Wallonie'
                        : formulaVersion.label.toLowerCase().includes('bruxelles') ? 'Bruxelles'
                            : 'France';
                }
            });
        }
    }

    static getProjectLogTypes() {
        return {
            import: {
                label: i18n.t("Import"),
                icon: faUpload
            },
            export: {
                label: i18n.t("Export"),
                icon: faDownload
            },
            element_addition: {
                label: i18n.t("Ajout d'éléments"),
                icon: faPlus
            },
            element_update: {
                label: i18n.t("Mise à jour d'éléments"),
                icon: faPen
            },
            element_deletion: {
                label: i18n.t("Suppression d'éléments"),
                icon: faTrash
            },
            collaborator_addition: {
                label: i18n.t("Ajout de collaborateurs"),
                icon: faUserPlus
            },
            collaborator_update: {
                label: i18n.t("Mise à jour de collaborateurs"),
                icon: faUserEdit
            },
            collaborator_deletion: {
                label: i18n.t("Suppression de collaborateurs"),
                icon: faUserMinus
            }
        };
    }

    static isElementLocked(lockedElements, feature, project, projectCollaborators = [], users) {
        const userId = jwt_decode(new Cookies().get('token')).id;
        const lockedElement = lockedElements.find(le => le.id === feature.id);
        const isElementLocked = (lockedElement && lockedElement.userId !== userId) ? true : false;
        if (isElementLocked && project && Array.isArray(users)) {
            const user = projectCollaborators.find(up => up.user.id === lockedElement.userId)?.user;
            const formattedUser = FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName);
            if (!users.includes(formattedUser)) {
                users.push(formattedUser);
                showToast('elements_locked', formattedUser);
            }
        }
        return isElementLocked;
    }

    static removeBaseProject(baseProjectId, path, baseProjects, setProjects) {
        if (!baseProjectId || !baseProjects?.length) return;
        if (!path) return baseProjects.filter(bp => bp.id !== baseProjectId);

        const pathSplit = (path?.split('/')?.filter(id => id.length) || []).map(id => Number(id));
        let folder = null
        for (let i = 0; i <= pathSplit.length - 1; i++) {
            if (!folder) folder = baseProjects.find(bp => bp.id === pathSplit[i]);
            else folder = (folder.childFolders || []).find(cf => cf.id === pathSplit[i]);
        }

        if (folder) {
            folder.childFolders = folder.childFolders.filter(cf => cf.id !== baseProjectId);
            folder.projects = folder.projects.filter(p => p.id !== baseProjectId);
        }

        if (setProjects) setProjects(baseProjects);
        return baseProjects;
    }

    static getBaseProject(baseProjectId, path, baseProjects) {
        if (!baseProjectId || !baseProjects?.length) return;
        if (!path) return baseProjects.find(baseProject => baseProject.id.toString() === baseProjectId.toString());

        const pathSplit = path?.split('/')?.filter(id => id.length) || [];
        pathSplit.push(baseProjectId.toString());
        let folder = baseProjects;
        for (const id of pathSplit) {
            const baseProject = folder.find(bp => bp.id.toString() === id);
            if (baseProject && baseProject?.id?.toString() !== baseProjectId.toString()) folder = [...(baseProject.childFolders || []), ...(baseProject.projects || [])];
            else if (baseProject) return baseProject;
        };

        return null;
    }

    static getAuthorizedSelectedBaseProjectIds(baseProjectsToMove, projects) {
        if (!baseProjectsToMove.length) return [];
        const userId = jwt_decode(new Cookies().get('token')).id;

        return baseProjectsToMove.filter(baseProject => // Vérification des droits
            projects.find(projectInProps => ((!baseProject.path && baseProject.id === projectInProps.id) || (baseProject.path && baseProject.path.includes(projectInProps.path || `/${projectInProps.id}/`))) &&
                projectInProps.userBaseProjects.find(ubp => ubp.userId === userId && ['owner', 'manager'].includes(ubp.projectRole.type)))).map(baseProject => baseProject.id);
    }

    static checkIfCanMoveTo(destination = { id: null, path: null }, userId, baseProjectsToMove, baseProjects) {
        if (!baseProjectsToMove?.length || destination.type === 'project') return false;

        let canMoveTo = true;
        let ownerChanged = false;
        const destinationUserBaseProjects = !destination.id ? [] : baseProjects.find(projectInProps => ((!destination.path && (destination.id === projectInProps.id)) || (destination.path && destination.path.includes(projectInProps.path || `/${projectInProps.id}/`))))?.userBaseProjects;
        const destinationOwner = destinationUserBaseProjects.find(dubp => dubp.projectRole.type === 'owner');
        const destinationUser = destinationUserBaseProjects.find(dubp => dubp.userId === userId);
        for (const baseProjectToMove of baseProjectsToMove) {
            if (!baseProjectToMove.path && !destination.id) { // Racine => Racine
                canMoveTo = false;
                break;
            }

            if (destination.id && baseProjectToMove.type === 'folder' && // Destination => Parent actuel ou Dossier actuel ou Dossier enfant d'un dossier déplacé
                (destination.path?.includes(baseProjectToMove.id) || destination.id === baseProjectToMove.id || destination.id === baseProjectToMove.parentdestinationId)) {
                canMoveTo = false;
                break;
            } else if (destination.id && baseProjectToMove.path) { // Destination => Parent actuel
                const splitPath = baseProjectToMove.path.split('/').filter(id => id.length);
                if (Number(splitPath[splitPath.length - 1]) === destination.id) {
                    canMoveTo = false;
                    break;
                }
            }

            if (canMoveTo) { // Vérification des droits
                const movedUserBaseProjects = baseProjects.find(projectInProps => ((!baseProjectToMove.path && (baseProjectToMove.id === projectInProps.id)) || (baseProjectToMove.path && baseProjectToMove.path.includes(projectInProps.path || `/${projectInProps.id}/`))))?.userBaseProjects;
                const movedOwner = movedUserBaseProjects.find(ubp => ubp.projectRole.type === 'owner');
                if (destinationOwner && destinationOwner.userId !== movedOwner.userId) ownerChanged = true;
                if (destinationOwner && destinationUser && movedOwner.userId !== destinationOwner.userId && (movedOwner.userId !== userId || !['owner', 'manager'].includes(destinationUser.projectRole.type))) {
                    canMoveTo = false;
                    break;
                }
            }
        }

        return { isAllowed: canMoveTo, ownerChanged };
    }

    static moveBaseProjects(desinationFolder, authorizedBaseProjectIds, baseProjectsToMove, baseProjects, currentFolder, rootFolder, webSocketHubs) {
        const folderId = desinationFolder?.id || 0;
        const folderPath = desinationFolder?.path;
        const newOrganizationId = desinationFolder?.organizationId;
        return new Promise((resolve) => {
            baseProjectsToMove = JSON.parse(JSON.stringify(baseProjectsToMove.filter(bptm => authorizedBaseProjectIds.includes(bptm.id))));
            ProjectsService.moveBaseProjects(authorizedBaseProjectIds, folderId, baseProjectsToMove[0].type).then((response) => {
                const userBaseProjectsAdded = response?.userProjectsAdded || [];
                const userBaseProjectsRemoved = response?.userProjectsRemoved || [];
                let usersToAlert = []; // Récupérer les users depuis le root
                baseProjects = JSON.parse(JSON.stringify(baseProjects));

                // Suppression des dossiers/projets à l'emplacement d'origine
                const originalPath = baseProjectsToMove[0].path;
                if (!originalPath) baseProjects = baseProjects.filter(project => !authorizedBaseProjectIds.includes(project.id));
                else {
                    const splitPath = originalPath.split('/').filter(id => id).map(id => Number(id));
                    let folder = baseProjects;
                    splitPath.forEach((id, index) => {
                        folder = folder.find(project => project.id === id);
                        usersToAlert = [...usersToAlert, ...(folder.userBaseProjects || [])];
                        if (index < splitPath.length - 1) folder = folder.childFolders;
                        else {
                            if (folder.childFolders) folder.childFolders = folder.childFolders.filter(cf => !authorizedBaseProjectIds.includes(cf.id));
                            if (folder.projects) folder.projects = folder.projects.filter(p => !authorizedBaseProjectIds.includes(p.id));
                            currentFolder = folder;
                        }
                    });
                }

                // Mise à jour des parentFolderIds, des paths et des userBaseProjects
                this.updatePath(baseProjectsToMove, folderId || null, folderId ? `${folderPath || '/'}${folderId}/` : null, userBaseProjectsAdded, userBaseProjectsRemoved);

                // Mise à jour de l'organisation
                if (newOrganizationId)
                    baseProjectsToMove.forEach(baseProject => {
                        baseProject.organizationId = newOrganizationId;
                        if (desinationFolder?.organization)
                            baseProject.organization = desinationFolder.organization;
                    });

                // Ajout des dossiers/projets dans le dossier de destination
                if (!folderId) baseProjects = [...baseProjects, ...baseProjectsToMove.filter(bptm => authorizedBaseProjectIds.includes(bptm.id))];
                else {
                    // Splitter le path et accéder au dossier parent puis ajouter les éléments
                    const destinationPath = `${folderPath || '/'}${folderId}/`;
                    const splitPath = destinationPath.split('/').filter(id => id).map(id => Number(id));
                    let folder = baseProjects;
                    splitPath.forEach((id, index) => {
                        folder = folder.find(project => project.id === id);
                        usersToAlert = [...usersToAlert, ...(folder.userBaseProjects || [])];
                        if (index < splitPath.length - 1) folder = folder.childFolders;
                        else {
                            if (!folder.childFolders) folder.childFolders = [];
                            if (!folder.projects) folder.projects = [];
                            folder.childFolders = [...folder.childFolders, ...baseProjectsToMove.filter(bptm => bptm.type === 'folder')];
                            folder.projects = [...folder.projects, ...baseProjectsToMove.filter(bptm => bptm.type === 'project')];
                        }
                    });
                }

                const currentProjectList = currentFolder ? [...(currentFolder.childFolders || []), ...(currentFolder.projects || [])] : baseProjects;
                rootFolder = rootFolder ? baseProjects.find(project => project.id === rootFolder.id) : null;
                const userId = jwt_decode(new Cookies().get('token')).id;
                usersToAlert = [...new Set(usersToAlert.filter(uta => uta.userId !== userId).map(uta => uta.userId))];
                WebSocketUtil.moveProjects(webSocketHubs, usersToAlert, baseProjectsToMove, originalPath);
                resolve({ currentProjectList, currentFolder, rootFolder, baseProjects });
            });
        });
    }

    static updatePath(baseProjects, parentFolderId, path, userBaseProjectsAdded, userBaseProjectsRemoved) {
        baseProjects.forEach(baseProject => {
            baseProject.parentFolderId = parentFolderId;
            baseProject.path = path;
            baseProject.userBaseProjects = [...(baseProject.userBaseProjects || []), ...(userBaseProjectsAdded || []).filter(ubp => ubp.baseProjectId === baseProject.id)];
            if (userBaseProjectsRemoved?.length)
                baseProject.userBaseProjects = baseProject.userBaseProjects.filter(ubp => !userBaseProjectsRemoved.find(ubpr => ubpr.baseProjectId === baseProject.id && ubpr.userId === ubp.userId));
            // Mise à jour récursive
            const newPath = `${path || '/'}${baseProject.id}/`;
            if (baseProject.projects?.length)
                baseProject.projects.forEach(project => {
                    project.path = newPath;
                    project.userBaseProjects = [...(project.userBaseProjects || []), ...(userBaseProjectsAdded || []).filter(ubp => ubp.baseProjectId === project.id)];
                    if (userBaseProjectsRemoved?.length)
                        project.userBaseProjects = project.userBaseProjects.filter(ubp => !userBaseProjectsRemoved.find(ubpr => ubpr.baseProjectId === project.id && ubpr.userId === ubp.userId));
                });
            if (baseProject.childFolders) this.updatePath(baseProject.childFolders, baseProject.id, newPath);
        });
    }

    static updateProjectsInProps(project, projects, formulas, projectInProps, setProjects, setProject) {
        if (projects) {
            this.assignProjectFormulaVersionsType(project, formulas);
            const path = project.path;
            let baseProject = this.getBaseProject(project.id, path, projects);
            for (const key in baseProject)
                if (baseProject.hasOwnProperty(key) && project.hasOwnProperty(key))
                    baseProject[key] = project[key];

            setProjects(JSON.parse(JSON.stringify(projects)));
            if (baseProject && projectInProps?.id === baseProject.id) setProject(baseProject);
        } else if (projectInProps?.id === project.id) setProject(project);
    }

    static mergeProjectsProperties(properties, removePlanifiedProjects = false) {
        if (removePlanifiedProjects) properties.baseProjects = properties.baseProjects.filter(bp => bp.type === 'folder' || bp.status !== 3);
        properties.baseProjects.forEach(baseProject => {
            if (baseProject.type === 'project') {
                const surroundings = JSON.parse(baseProject.surroundings);
                if (properties.bounds) properties.bounds = union(properties.bounds, surroundings);
                else properties.bounds = surroundings;
                properties.tags = [...properties.tags, ...(baseProject.tags || [])];
                properties.projectFormulaVersions = [...properties.projectFormulaVersions, ...(baseProject.projectFormulaVersions || [])];
                const requiredFields = baseProject.requiredFields ? [JSON.stringify({ ...JSON.parse(baseProject.requiredFields), projectId: baseProject.id })] : null;
                properties.requiredFields = [...properties.requiredFields, ...(requiredFields || [])];
                properties.projectLabels.push({
                    id: baseProject.id,
                    label: baseProject.label,
                    surroundings,
                    coordinates: centroid(surroundings).geometry.coordinates.toReversed()
                });
            } else {
                properties.baseProjects = [...(baseProject.childFolders || []), ...(baseProject.projects || [])];
                this.mergeProjectsProperties(properties, removePlanifiedProjects);
            }
        });
    }

    static getStatus() {
        return [
            { value: 1, text: i18n.t("Situation existante") },
            { value: 2, text: i18n.t("Situation existante - En cours d'inventaire") },
            { value: 3, text: i18n.t("Situation planifiée/projetée") }
        ];
    }

    static checkIfInsideSurroundings(shape, layer, surroundingsLayer, surroundings = null) {
        const surroundingsGeometry = surroundings || polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(surroundingsLayer.getLatLngs()));
        let geometryToCheck;
        switch (shape) {
            case 'marker': case 'point': geometryToCheck = point([layer.getLatLng().lng, layer.getLatLng().lat]); break;
            case 'line': geometryToCheck = lineString(GeometriesUtil.convertLineLatLngsToCoordinates(layer.getLatLngs())); break;
            case 'polygon': case 'rectangle': case 'circle':
                const newLayer = shape !== 'circle' ? layer : GeometriesUtil.convertCircleToPolygon(layer, null, 30);
                geometryToCheck = polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(newLayer.getLatLngs()));
                break;
            default: break;
        }

        const isIntersecting = surroundingsGeometry && geometryToCheck ? booleanIntersects(surroundingsGeometry, geometryToCheck) : false;
        return geometryToCheck && (surroundings ? isIntersecting : !isIntersecting);
    }
}
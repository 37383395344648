import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterList, faFloppyDisk, faFlowerTulip, faLasso, faMagnifyingGlass, faTablePicnic, faTimes, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import MapPreview from '../Utils/MapPreview';
import FormMemberList from '../Lists/FormMemberList';
/*     Editors     */
import TextEditor from './Editors/TextEditor';
import SmartTextEditor from './Editors/SmartTextEditor';
import NumberEditor from './Editors/NumberEditor';
import BooleanEditor from './Editors/BooleanEditor';
import DropDownEditor from './Editors/DropDownEditor';
/*     Filters     */
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import BooleanFilter from './Filters/BooleanFilter';
import DropDownFilter from './Filters/DropDownFilter';
// Librairies
import DataGrid, { Row as GridRow, SortableHeaderCell } from 'react-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setTableState } from '../../actionCreators/componentsActions';
import { setProject, setProjects } from '../../actionCreators/projectsActions';
// Semantic UI
import { Form, Button, Menu, Input, Label, Grid, Message, Dimmer, Transition } from 'semantic-ui-react';
// Services
import FurnituresService from '../../services/FurnituresService';
import FilesService from '../../services/FilesService';
import ProjectsService from '../../services/ProjectsService';
import StationsService from '../../services/StationsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import StylesUtil from '../../utils/StylesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import DatesUtil from '../../utils/DatesUtil';
import RightsUtil from '../../utils/RightsUtil';

const initialFilters = {
    global: '',
    condition: '',
    type: '',
    customReference: '',
    place: '',
    tags: '',
    description: '',
    projectReference: '',
    creationDate: '',
    modificationDate: '',
    projectLabel: ''
};

class FurnitureTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        furnituresToModify: [],
        furnituresToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: JSON.parse(JSON.stringify(initialFilters)),
        tableToShow: null,
        vernacularNames: [],
        conditions: [],
        furnitureTypes: [],
        tags: [],
        furnituresStations: [],
        showExports: false,
        featureToShow: null
    }

    render() {
        const { rights, selectedElements, mainFilters } = this.props;
        const {
            data, furnituresToModify, furnituresToDelete, modificationsHistory, modificationsHistoryIndex, featureToShow,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, tableToShow, showExports
        } = this.state;
        const mainPF = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators).main;
        const rows = this.getFilteredRows();
        const id = `furnitures-table-${this.props.project.id}`;

        return (
            <div className={'scrollable-container'}>
                {(this.props.closingTable || tableToShow) &&
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column textAlign='center'>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Header>{i18n.t("Des modifications n'ont pas été sauvegardées, que faire ?")}</Message.Header>
                                        <Message.Content style={{ marginTop: '10px' }}>
                                            <Button color='green' onClick={() => {
                                                if (!tableToShow) this.props.cancelTableClose();
                                                this.handleSubmit(true);
                                            }}>
                                                <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                            </Button>
                                            <Button color='red' onClick={() => {
                                                tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Ne pas sauvegarder")}
                                            </Button>
                                            <Button color='grey' onClick={() => {
                                                tableToShow ? this.setState({ tableToShow: null }) : this.props.cancelTableClose();
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                            </Button>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer>}
                {data && data.columns &&
                    <>
                        <Menu attached='top' tabular style={{ marginTop: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>

                                    {mainPF.trees &&
                                        <Button
                                            title={i18n.t("Voir les arbres")} className='button--secondary' icon='tree'
                                            onClick={() => {
                                                if (this.props.elementsHaveBeenModified) this.setState({ tableToShow: 'TreeTable' });
                                                else this.props.changeModalContentType('TreeTable', i18n.t("Tableau de données"), true);
                                            }}
                                        />}
                                    {mainPF.greenSpaces &&
                                        <Button
                                            title={i18n.t("Voir les espaces verts")} className='button--secondary' style={{ padding: '11px' }}
                                            onClick={() => {
                                                if (this.props.elementsHaveBeenModified) this.setState({ tableToShow: 'GreenSpaceTable' });
                                                else this.props.changeModalContentType('GreenSpaceTable', i18n.t("Tableau de données"), true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFlowerTulip} style={{ height: '12px' }} />
                                        </Button>}
                                    <Button className='button--secondary' disabled style={{ padding: '11px' }}>
                                        <FontAwesomeIcon icon={faTablePicnic} style={{ height: '12px' }} />
                                    </Button>
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <Button
                                        title={i18n.t("Visualiser les données")} className='button--secondary' icon='eye'
                                        onClick={this.showFilteredElements}
                                    />
                                    {RightsUtil.canExport(rights?.furnitures) && <>
                                        <Button
                                            title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                            disabled={!this.props.isOnline} onClick={() => this.setState(prevState => ({ showExports: !prevState.showExports }))}
                                        />
                                        {showExports &&
                                            <Button.Group style={{ position: 'absolute', top: '85%', left: '136px', zIndex: 1000 }}>
                                                <Button title='Excel' className='button--secondary' icon='file excel' disabled={!this.props.isOnline} onClick={this.exportXLSX} />
                                                <Button title='Shapefiles' className='button--secondary' icon='file' disabled={!this.props.isOnline} onClick={this.exportSHP} />
                                                <Button title='Fiches PDF' className='button--secondary' icon='file pdf' disabled={!this.props.isOnline} onClick={this.exportPDF} />
                                                <Button title={i18n.t("Photos")} className='button--secondary' icon='picture' disabled={!this.props.isOnline} onClick={this.exportPhotos} />
                                            </Button.Group>}
                                    </>}
                                    {RightsUtil.canWrite(rights?.furnitures) &&
                                        <>
                                            <Button
                                                title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                                onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                            />
                                            <Button
                                                title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                                disabled={modificationsHistoryIndex === modificationsHistory.length}
                                                onClick={this.restoreNextModification}
                                            />
                                            <Button
                                                title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                                onClick={() => this.handleSubmit(false)} disabled={furnituresToModify.length < 1 && furnituresToDelete.length < 1}
                                            />
                                        </>}
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item position='right' style={{ paddingBottom: 0, paddingTop: 26 }}>
                                <div style={{ position: 'absolute', right: '20px', top: '3px' }}>
                                    <FormMemberList
                                        id={id} stateToSend={{ rows: this.state.data.rows, furnituresToModify: this.state.furnituresToModify }}
                                        setIsLoading={(isLoading) => this.setState({ isLoading })} updateForm={this.updateForm}
                                    />
                                </div>
                                {furnituresToDelete.length > 0 &&
                                    <>
                                        <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + furnituresToDelete.length} />
                                        <span style={{ marginLeft: '10px' }}>|</span>
                                    </>}*
                                <Label color='grey' content={i18n.t("Les cases foncées ne sont pas éditables (référence automatique, latitude, longitude, ...)")} />
                            </Menu.Item>
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item element-input'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => {
                                            if (commitChanges) {
                                                this.handleRowChange(selectedRow);
                                                WebSocketUtil.updateForm(this.props.webSocketHubs, 'FurnitureTable' + this.props.project.id, { rows: [selectedRow], furnituresToModify: this.state.furnituresToModify });
                                            }
                                        }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <Input placeholder={i18n.t("Rechercher...")} onChange={(_, { value }) => this.setState(prevState => ({ filters: { ...prevState.filters, global: value } }))} />
                                <div style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', position: 'absolute', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <div style={{ borderRight: 'solid 1px var(--grey-100)', paddingRight: '6px', marginRight: '5px' }}>
                                        {mainFilters &&
                                            <FontAwesomeIcon
                                                icon={faFilterList} title={i18n.t("Outil filtres")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {selectedElements?.length > 0 &&
                                            <FontAwesomeIcon
                                                icon={faLasso} title={i18n.t("Sélection d'éléments")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {filters.global?.trim().length > 0 &&
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass} title={i18n.t("Recherche globable")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {enableFilterRow &&
                                            <FontAwesomeIcon
                                                icon={faFilter} title={i18n.t("Filtres du tableau")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                    </div>
                                    <span style={{ fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <div className='scrollable reset-height'>
                            <DataGrid
                                ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                columns={data.columns.filter(column => column.visible)}
                                rows={rows} rowRenderer={this.rowRenderer}
                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                cellNavigationMode='LOOP_OVER_ROW'
                                sortColumn={sortColumn} sortDirection={sortDirection}
                                onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                                filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}><span>{i18n.t("Aucun résultat trouvé")}</span></div>)}
                                onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                onRowsChange={(newRows, _) => {
                                    let rowsToSend = [];
                                    this.state.data.rows.forEach(row => {
                                        const index = newRows.findIndex(newRow => newRow.elementId === row.elementId);
                                        if (index !== -1 && JSON.stringify(row) !== JSON.stringify(newRows[index]))
                                            rowsToSend.push(newRows[index]);
                                    });

                                    this.handleRowsChange(newRows);
                                    if (rowsToSend.length) WebSocketUtil.updateForm(this.props.webSocketHubs, 'FurnitureTable' + this.props.project.id, { rows: rowsToSend, furnituresToModify: this.state.furnituresToModify });
                                }}
                            />
                        </div>
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.showRowElement}>{i18n.t("Voir (carte)")}</MenuItem>
                            <MenuItem onClick={this.showRowElementOnMinimap}>{i18n.t("Voir (aperçu)")}</MenuItem>
                            {RightsUtil.canWrite(rights?.furnitures) && <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>}
                            {RightsUtil.canWrite(rights?.furnitures) && <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>}
                        </ContextMenu>
                        <Transition.Group animation='scale' duration='1000'>
                            {featureToShow &&
                                <div style={{ height: '200px', width: '300px', position: 'absolute', bottom: '15px', right: '15px' }}>
                                    <MapPreview
                                        id={featureToShow.id} style={{ borderRadius: '10px', height: '100%', width: '100%' }} dragging={true} zooming={true}
                                        features={[featureToShow]} elementStyle={{ furniture: StylesUtil.getFurnitureStyle() }}
                                    />
                                    <FontAwesomeIcon icon={faXmarkLarge} size='1x' style={{ position: 'absolute', top: '7px', right: '7px', zIndex: 2, cursor: 'pointer' }} onClick={() => this.setState({ featureToShow: null })} />
                                </div>}
                        </Transition.Group>
                    </>}
            </div>
        );
    }

    componentDidMount = async () => {
        this.gridRef = React.createRef();
        if (this.props.tableState) {
            const { furnitures, data: oldData, timestamp, ...state } = this.props.tableState; // On copie le state sauvegardé mais sans la variable 'greenSpaces'
            this.furnitures = furnitures;
            const data = this.loadData(state.tags, oldData.rows);

            const modifiedFurnitures = this.furnitures.filter(layer => layer.feature.properties.modificationDate && layer.feature.properties.modificationDate > timestamp);
            modifiedFurnitures.forEach(layer => {
                const index = data.rows.findIndex(row => row.elementId === layer.feature.id);
                if (index !== -1) data.rows[index] = this.getRowValue(layer.feature, data.rows[index].id, state.tags);
            });
            if (modifiedFurnitures.length > 0) {
                state.furnituresToModify = state.furnituresToModify.filter(feature => !modifiedFurnitures.find(layer => layer.feature.id === feature.id));
                data.rows = [...data.rows];
            }

            this.setState({ data, ...state });
            this.props.setTableState(null);
        } else {
            if (this.props.nbStations) await this.loadFurnituresStations();
            let projectTags = [];
            if (this.props.project?.tags)
                projectTags = this.props.project.tags
                    .filter(x => x.category === 'Mobilier')
                    .map(projectTag => { return { label: projectTag.label, id: projectTag.id, category: projectTag.category } });

            const data = this.loadData(projectTags);

            this.setState({
                data: data,
                conditions: this.props.conditions.map(x => { return { label: x.label, id: x.id } }),
                furnitureTypes: this.props.furnitureTypes.map(x => { return { label: x.label, id: x.id } }),
                tags: projectTags
            });
        }
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('click', ({ target }) => {
            const isExportButton = target.classList?.contains('download') || target.firstChild?.classList?.contains('download');
            if (!isExportButton && this.state.showExports) this.setState({ showExports: false });
        });

        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', (elements) => {
                let areColumnsInitialized = false;
                while (!areColumnsInitialized)
                    if (this.state.data.columns) {
                        const elementsParsed = JSON.parse(elements);
                        const type = elementsParsed[0].properties.category;
                        if (type !== 'Mobilier') return;
                        setTimeout(() => {
                            let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                            const layers = this.props.furnituresLayer.getLayers().filter(layer => elementsParsed.find(tp => tp.id === layer.feature.id));
                            if (layers.length) {
                                this.furnitures = [...this.furnitures || [], ...layers];
                                for (const key in layers)
                                    if (layers.hasOwnProperty(key))
                                        rows.push(this.getRowValue(layers[key].feature, key, this.state.tags));
                                this.setState(prevState => ({ data: { ...prevState.data, rows } }));
                            }
                        }, 500);
                        areColumnsInitialized = true;
                    }
            });

            this.props.webSocketHubs.elementsHub.on('UpdateElements', (elements) => {
                const elementsParsed = JSON.parse(elements);
                const type = elementsParsed[0].properties.category;
                if (type !== 'Mobilier') return;
                let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                let furnituresToModify = JSON.parse(JSON.stringify(this.state.furnituresToModify));
                const layers = this.props.furnituresLayer.getLayers().filter(layer => elementsParsed.find(tp => tp.id === layer.feature.id));
                for (const key in layers)
                    if (layers.hasOwnProperty(key)) {
                        // Mise à jour de la row
                        let index = rows.findIndex(r => r.elementId === layers[key].feature.id);
                        if (index !== -1)
                            rows[index] = { ...rows[index], lat: layers[key].feature.geometry.coordinates[1], long: layers[key].feature.geometry.coordinates[0] };
                        // Mise à jour des modifications
                        index = furnituresToModify.findIndex(ttm => ttm.id === layers[key].feature.id);
                        if (index !== -1) furnituresToModify[index].geometry = layers[key].feature.geometry;
                    }
                this.setState(prevState => ({ data: { ...prevState.data, rows }, furnituresToModify }));
            });

            this.props.webSocketHubs.elementsHub.on('RemoveElements', (type, ids) => {
                if (type !== 'Mobilier') return;
                let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                let furnituresToModify = JSON.parse(JSON.stringify(this.state.furnituresToModify));
                let modificationsHistory = JSON.parse(JSON.stringify(this.state.modificationsHistory));
                const idsParsed = JSON.parse(ids);
                this.furnitures = this.furnitures.filter(t => !idsParsed.includes(t.feature.id));
                rows = rows.filter(r => !idsParsed.includes(r.elementId));
                furnituresToModify = furnituresToModify.filter(ttm => !idsParsed.includes(ttm.id));
                for (let i = 0; i < modificationsHistory.length; i++)
                    modificationsHistory[i] = modificationsHistory[i].filter(m => !idsParsed.includes(m.elementId))
                modificationsHistory = modificationsHistory.filter(mh => mh.length);
                let modificationsHistoryIndex = modificationsHistory.length;
                this.setState(prevState => ({ data: { ...prevState.data, rows }, furnituresToModify, modificationsHistory, modificationsHistoryIndex }));
            });
        }
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);
    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.elementId === newRow.elementId);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, color) => (
        <div className={color ? 'headerCellOverride' : null} ref={(node) => { if (color) node?.style?.setProperty('background-color', color, 'important'); }}>
            <SortableHeaderCell
                column={column}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            >
                {column.name}
            </SortableHeaderCell>
        </div>
    );

    getEditor = (type, row, column, onRowChange, onClose, propertyOptions = null) => {
        const props = {
            elements: this.furnitures, elementsToModify: this.state.furnituresToModify, propertyOptions: propertyOptions,
            row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
            pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeFurnituresToModify,
            updateElementCustomFields: this.updateElementCustomFields
        };
        switch (type) {
            case 'dropdown': return <DropDownEditor {...props} />;
            case 'smart': return <SmartTextEditor {...props} />;
            case 'number': return <NumberEditor {...props} />;
            case 'url': case 'text': return <TextEditor {...props} />;
            case 'boolean': return <BooleanEditor {...props} />;
            default: return;
        }
    }

    loadData = (projectTags, rows) => {
        let data = {
            columns: [],
            rows: rows || []
        };

        if (this.props.linkedFurnitures && this.props.linkedFurnitures.length > 0) {
            if (!rows) {
                this.furnitures = [];
                this.props.linkedFurnitures.forEach(furnituresArray => {
                    if (this.props.furnituresLayer.hasLayer(furnituresArray[0]))
                        this.furnitures.push(furnituresArray[0]);
                });
            }
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project).furnitures;
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            const mainPF = publicFields.main;
            const furnituresPF = publicFields.furnitures;
            const projections = this.props.projections;
            const projectionId = this.props.project?.projectionId;

            // Définition des colonnes
            this.customFieldFilters = [];
            data.columns = [
                {
                    name: i18n.t("Référence"), key: 'projectReference', width: 100,
                    sortable: true, editable: false, frozen: true, visible: mainPF.references,
                    formatter: (props) => <div className='disabled'>{props.row.projectReference}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />
                },
                {
                    name: i18n.t("Référence personnalisée"), key: 'customReference', width: 180,
                    sortable: true, frozen: true, visible: requiredFields.customReference && mainPF.references,
                    formatter: (props) => props.row.customReference || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.furnitures),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Lieu"), key: 'place', width: 250,
                    sortable: true, visible: requiredFields.place && furnituresPF.place,
                    formatter: (props) => props.row.place || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.furnitures),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Stations"), key: 'stations', width: 250,
                    sortable: true, visible: this.props.nbStations > 0,
                    formatter: (props) => <div className='disabled'>{props.row.stations}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                },
                {
                    name: i18n.t("Tags"), key: 'tags', width: 200,
                    sortable: false, visible: requiredFields.tags && furnituresPF.tags,
                    formatter: (props) => {
                        if (props.row.tags === null) return '';
                        const lastIndex = props.row.tags.lastIndexOf(',');
                        const tags = props.row.tags.substring(lastIndex + 1, lastIndex + 3).trim() === '-'
                            ? props.row.tags.slice(0, lastIndex + 1) + props.row.tags.slice(lastIndex + 3, props.row.tags.length)
                            : props.row.tags || '';
                        return tags;
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.furnitures),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.tags)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Emplacement')),
                {
                    name: i18n.t("État"), key: 'condition', width: 110,
                    sortable: true, visible: requiredFields.condition && furnituresPF.condition,
                    formatter: (props) => props.row.condition || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.conditions} isNullable />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.furnitures),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.conditions),
                    editorOptions: { editOnClick: true }
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'État')),
                {
                    name: i18n.t("Type"), key: 'type', width: 110,
                    sortable: true, visible: requiredFields.type && furnituresPF.type,
                    formatter: (props) => props.row.type || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.furnitureTypes} isNullable />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.furnitures),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.furnitureTypes),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Description"), key: 'description', width: 500,
                    sortable: true, visible: requiredFields.description && furnituresPF.description,
                    formatter: (props) => props.row.description || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.furnitures),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Description')),
                ...this.getCustomCategoriesColumns(),
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Autres')),
                {
                    name: i18n.t("Date de création"), key: 'creationDate', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.creationDate}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Dernière modification"), key: 'modificationDate', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.modificationDate}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Latitude (y)"), key: 'lat', width: 160,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => {
                        const coordinates = GeoJsonUtil.projectMarkerCoordinates([props.row.long, props.row.lat], projections, projectionId);
                        return <div className='disabled'>{coordinates[1]}</div>
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props)
                },
                {
                    name: i18n.t("Longitude (x)"), key: 'long', width: 160,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => {
                        const coordinates = GeoJsonUtil.projectMarkerCoordinates([props.row.long, props.row.lat], projections, projectionId);
                        return <div className='disabled'>{coordinates[0]}</div>
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props)
                },
                {
                    name: i18n.t("Projet"), key: 'projectLabel', width: 170,
                    sortable: true, editable: false, visible: this.props.project?.type === 'folder',
                    formatter: (props) => <div className='disabled'>{props.row.projectLabel}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                }
            ];

            this.setState(({ filters }) => {
                this.customFieldFilters.forEach(customFieldId => filters[customFieldId] = '');
                return { filters };
            });

            // Ajout des données
            if (!rows) {
                const layers = this.furnitures;
                for (const key in layers)
                    if (layers.hasOwnProperty(key))
                        data.rows.push(this.getRowValue(layers[key].feature, key, projectTags));

                data.rows.sort((a, b) => a.projectReference - b.projectReference);
                let initialOrder = [];
                data.rows.forEach(row => initialOrder.push(row.elementId));
                this.setState({ initialOrder: initialOrder });
            }
        }
        return data;
    }

    loadFurnituresStations = () => {
        return new Promise((resolve, reject) => {
            StationsService.getElementStations('Furnitures', this.props.project.id).then((furnituresStations) => {
                if (furnituresStations) this.setState({ furnituresStations }, resolve);
                else if (this.props.isOnline) reject();
                else resolve();
            });
        });
    }

    getCustomColumns = (category) => {
        const { activeOrganization, customFields, project } = this.props;
        const areCustomFieldsAvailable = (project.organization || activeOrganization).subscription.customFields;
        if (!areCustomFieldsAvailable) return [];

        const projectCustomFields = [...(project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map((projectCustomField) => {
            const customField = customFields.find(cf => cf.id === projectCustomField.customFieldId);
            this.customFieldFilters.push(String(customField.id));

            return customField?.category === 'Mobilier' ? {
                name: customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : ''), key: customField.id, width: 180,
                sortable: true, visible: true, customField,
                formatter: (props) => (
                    props.row[String(customField.id)] ? (
                        customField.type === 'url'
                            ? <a href={props.row[String(customField.id)]?.includes('http') ? props.row[String(customField.id)] : '//' + props.row[String(customField.id)]} target='_blank' rel='noreferrer'>{props.row[String(customField.id)]}</a>
                            : customField.type === 'formula'
                                ? FormattersUtil.formatFormulaCustomField(customField, props.row[String(customField.id)])
                                : props.row[String(customField.id)] + (customField.type === 'number' && customField.unit?.trim() ? customField.unit : '')
                    ) : ''
                ),
                headerRenderer: (props) => this.getHeaderRenderer(props, category.color),
                filterRenderer: p => (
                    customField.type === 'boolean' ? <BooleanFilter p={p} />
                        : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? <TextFilter p={p} />
                            : customField.type === 'number' ? <NumberFilter p={p} step={customField.step} />
                                : customField.type === 'list' ? <DropDownFilter p={p} propertyOptions={customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))} isNullable />
                                    : null
                ),
                editable: (props) => RightsUtil.canWrite(this.props.rights?.furnitures) && !(
                    (props.isEmpty === i18n.t("Oui") && !customField.forEmpty) ||
                    (props.isDead === i18n.t("Oui") && !customField.forDead) ||
                    (props.isStump === i18n.t("Oui") && !customField.forStump)
                ),
                editor: ({ row, column, onRowChange, onClose }) => this.getEditor(
                    customField.type === 'list' ? customField.isMultiple ? 'smart' : 'dropdown' : customField.type,
                    row, column, onRowChange, onClose, customField.dropdownCustomFieldValues?.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))
                ),
                editorOptions: { editOnClick: ['boolean', 'list'].includes(customField.type) }
            } : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    getCustomCategoriesColumns = () => {
        const { project } = this.props;
        const fieldCategories = project?.fieldCategories || [];
        const fieldCategoriesToRender = fieldCategories.filter(fieldCategory => fieldCategory.category === 'Mobilier' && (project?.projectCustomFields || []).find(pcf => pcf.fieldCategoryId === fieldCategory.id));
        return fieldCategoriesToRender.flatMap(fieldCategory => this.getCustomColumns(fieldCategory));
    }

    getFilteredRows = () => {
        const { selectedElements } = this.props;
        const { filters, data, enableFilterRow } = this.state;
        let rows = [...data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return (!selectedElements?.length || selectedElements.find(el => el.feature.id === r.elementId))
                && (!filters.global?.trim().length || Object.keys(r).filter(property => !['id', 'elementId'].includes(property)).some(property => (r[property] || r[property] === 0) && $(`${r[property]}`).includes($(filters.global))))
                && (!enableFilterRow || (
                    (filters.condition ? (r.condition === filters.condition || (filters.condition === 'empty' && !r.condition)) : true)
                    && (filters.type ? (r.type === filters.type || (filters.type === 'empty' && !r.type)) : true)
                    && (filters.customReference ? $(r.customReference)?.includes($(filters.customReference)) : true)
                    && (filters.place ? $(r.place)?.includes($(filters.place)) : true)
                    && (filters.tags ? $(r.tags)?.includes($(filters.tags)) : true)
                    && (filters.description ? $(r.description)?.includes($(filters.description)) : true)
                    && (filters.creationDate ? $(r.creationDate)?.includes($(filters.creationDate)) : true)
                    && (filters.modificationDate ? $(r.modificationDate)?.includes($(filters.modificationDate)) : true)
                    && (filters.projectLabel ? $(r.projectLabel)?.includes($(filters.projectLabel)) : true)
                    && (filters.projectReference ? r.projectReference === Number(filters.projectReference) : true)
                    && data.columns.filter(column => column.customField).every(({ key, customField }) => (
                        filters[key] ? (
                            customField.type === 'boolean' ? r[key] === filters[key]
                                : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? $(r[key])?.includes($(filters[key]))
                                    : (r[key] === filters[key] || (filters[key] === 'empty' && !r[key]))
                        ) : true
                    )))
                );
        });
    }

    getHistoryPropertyValue = (properties) => {
        if (properties.length < 1) return;
        return this.searchForPropertyOptions(properties[0].property, properties[0].value, properties[0].customField);
    }

    getRowValue = (feature, key, projectTags) => {
        const properties = feature.properties;
        const projectReference = properties.projectReference;
        const lat = feature.geometry.coordinates[1];
        const long = feature.geometry.coordinates[0];
        const place = properties.place;
        const condition = this.props.conditions.find(x => x.id === properties.conditionId)?.label;
        const type = this.props.furnitureTypes.find(x => x.id === properties.typeId)?.label;
        let tags = '';
        if (properties.tagId)
            for (let i = 0; i < properties.tagId.length; i++) {
                const tag = projectTags.find(x => x.id === properties.tagId[i]);
                if (tag) tags += !tags ? tag.label : ', ' + tag.label
            }
        const description = properties.description;
        const customReference = properties.customReference;
        const creationDate = `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`;
        const modificationDate = `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}`;
        const projectLabel = this.props.project?.projectLabels?.find(pl => pl.id === feature.projectId)?.label;
        const stations = this.state.furnituresStations[feature.id];

        const row = {
            id: key, elementId: feature.id, projectReference, lat, long, place, condition, type, tags,
            description, customReference, creationDate, modificationDate, projectLabel, stations
        };

        if (properties.customFields) {
            Object.keys(properties.customFields).forEach(key => {
                const customField = this.props.customFields.find(customField => customField.id === Number(key));
                if (customField) {
                    row[key] = customField.type === 'boolean' ? properties.customFields[key] === 'true' ? i18n.t("Oui") : i18n.t("Non")
                        : customField.type === 'date'
                            ? DatesUtil.getFormattedLocaleDateString(properties.customFields[key])
                            : customField.type === 'list'
                                ? customField.isMultiple
                                    ? properties.customFields[key].split(',').map(id => customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(id))?.label).join(', ')
                                    : customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(properties.customFields[key]))?.label
                                : properties.customFields[key];
                }
            });
        }

        return row;
    }

    getPropertyName = (columnKey) => {
        let property = columnKey;
        if (['condition', 'type'].includes(columnKey))
            property = property + 'Id';
        else if (columnKey === 'tags') property = property.substring(0, property.length - 1) + 'Id'
        return property;
    }

    getTargetRowPropertyValue = (columnKey, sourceRow, targetRow, customField) => {
        if (!sourceRow[columnKey]) return;
        return this.searchForPropertyOptions(columnKey, sourceRow[columnKey], customField);
    }

    updateElementCustomFields = (feature, property, value) => {
        if (value) {
            if (!feature.properties.customFields) feature.properties.customFields = {};
            feature.properties.customFields[property] = value;
        } else if (feature.properties.customFields)
            delete feature.properties.customFields[property];
    }

    changeFurnituresToModify = (furnituresToModify) => {
        if (!this.props.elementsHaveBeenModified) this.props.setElementsHaveBeenModified(true);
        this.setState({ furnituresToModify: furnituresToModify });
    }

    changeFurnituresToModifyLocally = (elementId, property, value, furnituresToModify, customField) => {
        const index = furnituresToModify.findIndex(x => x.id === elementId);
        if (customField) this.updateElementCustomFields(furnituresToModify[index], property, value)
        else furnituresToModify[index].properties[property] = value;

        return furnituresToModify;
    }

    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    searchForPropertyOptions = (property, value, customField) => {
        let propertyOptions;
        switch (property) {
            case 'condition': propertyOptions = this.state.conditions; break;
            case 'type': propertyOptions = this.state.furnitureTypes; break;
            case 'tags': propertyOptions = this.state.tags; break;
            default:
                if (customField?.type === 'boolean')
                    propertyOptions = [{ label: i18n.t("Oui"), id: 'true' }, { label: i18n.t("Non"), id: 'false' }];
                else if (customField?.dropdownCustomFieldValues)
                    propertyOptions = customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }));
                break;
        }
        if (!propertyOptions) return value;
        else {
            if (customField?.isMultiple || property === 'tags') {
                const values = value?.split(',') || [];
                let ids = [];
                values.forEach(value => {
                    let linkedValue;
                    if (value.trim() !== '') linkedValue = propertyOptions.find(x => x.label === value.trim());
                    if (linkedValue) ids.push(linkedValue.id);
                    else if (property === 'tags') {
                        let trueValue = value.substring(0, 2).trim() === '-' ? value.substring(2).trim() : value.trim();
                        if (trueValue !== '')
                            ids.push(trueValue);
                    }
                });
                return customField ? ids.join(',') : ids;
            }

            let option = propertyOptions.find(x => x.label === value);
            return customField ? (option?.id ? String(option.id) : '') : option?.id || 0;
        };
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];
        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { furnituresToModify, furnituresToDelete } = this.state;

            let modificationsToCreate = [];
            let previousElementsId = [];
            let previousElementsProperties = [];
            let rowsToSend = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    const row = data.rows.find(x => x.elementId === modification.elementId);
                    modificationsToCreate.push({ property: modification.property, elementId: modification.elementId, oldValue: row[modification.property], customField: modification.customField });
                    row[modification.property] = modification.oldValue;
                    if (row.elementId === this.state.selectedRow?.elementId) this.updateSelectedRow(row);
                    rowsToSend.push(row);

                    const property = this.getPropertyName(modification.property);
                    const value = this.getHistoryPropertyValue([{ property: modification.property, value: modification.oldValue, customField: modification.customField }]);
                    furnituresToModify = this.changeFurnituresToModifyLocally(modification.elementId, property, value, furnituresToModify, modification.customField);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    furnituresToDelete = furnituresToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }
            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [];
                let actualElementsProperties = [];
                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));
                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)) {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data, furnituresToModify, furnituresToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }), () => WebSocketUtil.updateForm(this.props.webSocketHubs, 'FurnitureTable' + this.props.project.id, { rows: rowsToSend, furnituresToModify }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];
        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { furnituresToModify, furnituresToDelete } = this.state;

            let modificationsToCreate = [];
            let nextElementsId = [];
            let nextElementsProperties = [];
            let rowsToSend = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    const row = data.rows.find(x => x.elementId === modification.elementId);
                    nextElementsId.push(modification.elementId);
                    nextElementsProperties.push(modification.property);
                    modificationsToCreate.push({ property: modification.property, elementId: modification.elementId, oldValue: row[modification.property], customField: modification.customField });
                    row[modification.property] = modification.oldValue;
                    if (row.elementId === this.state.selectedRow?.elementId) this.updateSelectedRow(row);
                    rowsToSend.push(row);

                    const property = this.getPropertyName(modification.property);
                    const value = this.getHistoryPropertyValue([{ property: modification.property, value: modification.oldValue, customField: modification.customField }]);
                    furnituresToModify = this.changeFurnituresToModifyLocally(modification.elementId, property, value, furnituresToModify, modification.customField);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    furnituresToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            let modificationsHistory;
            let actualElementsId = [];
            let actualElementsProperties = [];
            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));
            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data, furnituresToModify, furnituresToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }), () => WebSocketUtil.updateForm(this.props.webSocketHubs, 'FurnitureTable' + this.props.project.id, { rows: rowsToSend, furnituresToModify }));
        }
    }

    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);

    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        const { furnituresToModify, data } = this.state;
        let property = this.getPropertyName(columnKey);
        const customField = data.columns.find(column => column.key === columnKey)?.customField;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getTargetRowPropertyValue(columnKey, sourceRow, row, customField);
            const index = furnituresToModify.findIndex(x => x.id === row.elementId);
            if (index === -1) { // On modifie les propriétés du mobilier urbain
                const feature = JSON.parse(JSON.stringify(this.furnitures[row.id].feature));
                if (customField) this.updateElementCustomFields(feature, property, value);
                else feature.properties[property] = value;
                furnituresToModify.push(feature);
            } else {
                if (customField) this.updateElementCustomFields(furnituresToModify[index], property, value);
                else furnituresToModify[index].properties[property] = value;
            }
        });
        if (rowsUpdated) this.changeFurnituresToModify(furnituresToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.elementId, oldValue: row[columnKey], customField });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });

        WebSocketUtil.updateForm(this.props.webSocketHubs, 'FurnitureTable' + this.props.project.id, { rows: newRows, furnituresToModify });
        this.pushToModificationsHistory(modificationsToCreate);
        return newRows;
    }

    handleSubmit = (shouldClose) => {
        const { project, projects, formulas } = this.props;
        const tableToShow = this.state.tableToShow;
        if (tableToShow) this.setState({ tableToShow: null });

        const guidRegExp = new RegExp('({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}');
        let tagsToAdd = [];
        let { furnituresToModify, furnituresToDelete } = this.state;
        let layersToModify = [];
        let furnituresNotToModifyAnymore = [];
        furnituresToModify.forEach(furnitureToModify => {
            const properties = { ...furnitureToModify.properties };
            if (JSON.stringify(properties) === JSON.stringify(this.furnitures.find(x => x.feature.id === furnitureToModify.id).feature.properties)
                || furnituresToDelete.find(furniture => furniture.id === furnitureToModify.id))
                furnituresNotToModifyAnymore.push(furnitureToModify);
            else furnitureToModify.properties = properties;
        });
        furnituresToModify = furnituresToModify.filter(furniture => !furnituresNotToModifyAnymore.includes(furniture));
        for (let i = 0; i < furnituresToModify.length; i++) { // Pour chaque mobilier à modifier
            layersToModify.push(this.props.linkedFurnitures.find(x => x[0].feature.id === furnituresToModify[i].id));
            let tagId = furnituresToModify[i].properties.tagId;
            if (tagId) {
                for (let j = 0; j < tagId.length; j++) { // Pour chaque tag du mobilier urbain
                    let tag = tagId[j];
                    // Si le tag n'est pas un GUID et n'est pas encore dans la liste d es tags à ajouter
                    if (!guidRegExp.test(tag)) {
                        if (!tagsToAdd.find(x => x.label === tag))
                            tagsToAdd.push({ id: uuidv4(), label: tag, projectId: this.props.project.id, category: 'Mobilier' });
                        tagId[j] = tagsToAdd.find(x => x.label === tag).id;
                    }
                }
            }
        }

        if (tagsToAdd.length > 0) {
            ProjectsService.addProjectTags(tagsToAdd).then(response => {
                if (response) {
                    let project = this.props.project;
                    project.tags = response;
                    this.props.setProject(project);
                }
            });
        }

        if (furnituresToModify.length > 0 && furnituresToModify.length === layersToModify.length) {
            this.props.showLoader(true, 'modal', i18n.t("Mise à jour des mobiliers en cours..."));
            UpdatesUtil.bulkUpdateFurnitures(layersToModify, furnituresToModify, this.props.fieldList, this.props.project.id, this.props.furnituresLayer.activeChild, 'updating', this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps }).finally(() => {
                this.props.showLoader(false);
                this.props.updateLegend(i18n.t("Mobilier urbain"));
                if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
            });
        } else {
            if (furnituresToModify.length > 0) showToast('furnitures_updated');
            if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
        }

        if (furnituresToDelete.length > 0) {
            const layersToDelete = this.furnitures.filter(furniture => furnituresToDelete.find(furnitureToDelete => furnitureToDelete.elementId === furniture.feature.id));

            this.props.showLoader(true, 'modal', i18n.t("Suppression des mobiliers en cours..."));
            FurnituresService.removeFurnitures(layersToDelete.map(furniture => furniture.feature), this.props.webSocketHubs).finally(() => {
                // Suppression des arbres sur la carte
                this.props.furnituresLayer.eachLayer(layer => {
                    if (layersToDelete.find(l => l.feature.id === layer.feature?.id))
                        this.props.furnituresLayer.removeLayer(layer); // On supprime l'élément du layer
                });

                this.props.furnituresLayerNotClustered.eachLayer(layer => {
                    if (layersToDelete.find(l => l.feature.id === layer.feature?.id))
                        this.props.furnituresLayerNotClustered.removeLayer(layer); // On supprime l'élément du layer
                });

                // Suppression des liens
                const ids = furnituresToDelete.map(ftd => ftd.elementId);
                if (ids.length && project?.linkedElements?.length) {
                    let updateProject = JSON.parse(JSON.stringify(project));
                    updateProject.linkedElements = updateProject.linkedElements.filter(le => !ids.includes(le.elementId) && !ids.includes(le.linkedElementId));
                    if (updateProject.linkedElements.length !== project.linkedElements.length)
                        ProjectsUtil.updateProjectsInProps(updateProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
                }

                this.props.showLoader(false);
                this.props.updateLegend(i18n.t("Mobilier urbain"));
                if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
            });
        } else if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);

        this.props.setElementsHaveBeenModified(false);
        this.setState({ furnituresToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));

    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (let property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    clearFilters = () => {
        const filters = JSON.parse(JSON.stringify(initialFilters));
        this.state.data.columns.filter(column => column.customField).forEach(column => filters[String(column.customField.id)] = '');
        this.setState({ filters });
    }

    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(x => x.elementId === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            const customField = this.state.data.columns.find(column => column.key === sortColumn)?.customField;
            if (['projectReference', 'lat', 'long'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (['creationDate', 'modificationDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else if (customField?.type === 'number')
                rows = rows.sort((a, b) => {
                    if (!a[sortColumn]) return -1;
                    if (!b[sortColumn]) return 1;
                    return Number(a[sortColumn]) - Number(b[sortColumn]);
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    resetRow = (_, { rowIdx }) => {
        let furnituresToModify = this.state.furnituresToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        const layers = this.furnitures;
        let filteredRows = this.getFilteredRows();
        let rowToSend;
        for (const key in layers) {
            if (layers[key].feature.id === filteredRows[rowIdx].elementId) {
                const index = furnituresToModify.findIndex(x => x.id === filteredRows[rowIdx].elementId);
                if (index !== -1) {
                    furnituresToModify[index] = JSON.parse(JSON.stringify(layers[key].feature));
                    const newDisplayedData = this.getRowValue(layers[key].feature, key, this.state.tags);
                    let modificationsToCreate = [];
                    let rowIndex = data.rows.findIndex(x => x.elementId === filteredRows[rowIdx].elementId);
                    const properties = [...new Set([...Object.keys(newDisplayedData), ...Object.keys(data.rows[rowIndex])])];
                    for (const property of properties)
                        if (data.rows[rowIndex][property] !== newDisplayedData[property]) {
                            const customField = data.columns.find(column => column.key === Number(property))?.customField;
                            modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].elementId, oldValue: data.rows[rowIndex][property], customField });
                        }
                    if (modificationsToCreate.length > 0)
                        this.pushToModificationsHistory(modificationsToCreate);
                    data.rows[rowIndex] = newDisplayedData;
                    rowToSend = data.rows[rowIndex];
                    this.updateSelectedRow(newDisplayedData);
                }
            }
        }

        this.setState({ data, furnituresToModify }, () => {
            if (rowToSend) WebSocketUtil.updateForm(this.props.webSocketHubs, 'FurnitureTable' + this.props.project.id, { rows: [rowToSend], furnituresToModify })
        });
    }

    deleteRow = (_, { rowIdx }) => {
        let furnituresToDelete = this.state.furnituresToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.data.rows.find(element => filteredRows[rowIdx].id === element.id);
        furnituresToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: initialElement.elementId, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, furnituresToDelete, selectedRow: null, selectedColumn: null });
    }

    exportXLSX = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        FurnituresService.exportFurnituresFromProjectAsExcel(this.props.project.label, this.props.project.id, elementsToExport, projection);
    }

    exportSHP = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        FurnituresService.exportFurnituresAsSHP(this.props.project.label, this.props.project.id, elementsToExport, projection);
    }

    exportPDF = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        FurnituresService.exportFurnituresAsPDF(this.props.project.id, elementsToExport, projection);
        showToast('pdfs_exporting');
    }

    exportPhotos = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        FilesService.exportProjectPhotos(this.props.project.id, ['furnitures'], elementsToExport);
        showToast('photos_exporting');
    }

    showRowElement = (_, { rowIdx }) => {
        this.props.setTableState({ ...this.state, furnitures: this.furnitures, timestamp: new Date().toISOString(), selectedRow: null, selectedColumn: null }).then(() => {
            const layers = this.furnitures;
            for (const key in layers) {
                if (layers[key].feature.id === this.getFilteredRows()[rowIdx].elementId)
                    this.props.showElement('Mobilier urbain', layers[key].feature, 'FurnitureTable');
            }
        });
    }

    showRowElementOnMinimap = (_, { rowIdx }) => {
        const layers = this.furnitures;
        for (const key in layers)
            if (layers[key].feature.id === this.getFilteredRows()[rowIdx].elementId)
                this.setState({ featureToShow: layers[key].feature });
    }

    showFilteredElements = () => {
        this.props.setTableState({ ...this.state, furnitures: this.furnitures, timestamp: new Date(), selectedRow: null, selectedColumn: null }).then(() => {
            this.props.showElements('Mobilier urbain', this.getFilteredRows())
        });
    }

    updateForm = (state) => {
        let rows = JSON.parse(JSON.stringify(this.state.data.rows));
        let furnituresToModify = JSON.parse(JSON.stringify(this.state.furnituresToModify));
        let selectedRow = this.state.selectedRow ? JSON.parse(JSON.stringify(this.state.selectedRow)) : null;
        const stateParsed = JSON.parse(state);
        const newRows = stateParsed.rows;
        newRows.forEach(newRow => {
            const index = rows.findIndex(r => r.elementId === newRow.elementId);
            if (index !== -1) rows[index] = newRow;
            if (selectedRow?.elementId === newRow.elementId) selectedRow = newRow;
        });
        const newFurnituresToModify = stateParsed.furnituresToModify;
        newFurnituresToModify.forEach(newFurnitureToModify => {
            const index = furnituresToModify.findIndex(ttm => ttm.id === newFurnitureToModify.id);
            if (index !== -1) furnituresToModify[index] = newFurnitureToModify;
            else furnituresToModify.push(newFurnitureToModify);
        });

        this.setState(prevState => ({ isLoading: false, data: { ...prevState.data, rows }, furnituresToModify, selectedRow }));
    }
}

const mapStateToProps = (state) => {
    return {
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        tableState: state.tableState,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        projections: state.projections,
        webSocketHubs: state.webSocketHubs,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setTableState,
    setProject,
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(FurnitureTable);
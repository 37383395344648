import React, { Component } from 'react';
// Composants
import { Tab } from 'semantic-ui-react';
import FieldsManagement from '../../Utils/FieldsManagement';
import FormulasForm from './FormulasForm';
// Librairies
import i18n from '../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setProjectCustomFields } from '../../../actionCreators/projectsActions';
// Ressources
import CarbonStockPng from '../../../resources/pngs/carbon-stock.png';
import CoolingIndicatorPng from '../../../resources/pngs/cooling-indicator.png';
import AmenityValuePng from '../../../resources/pngs/tree-money.png';
import OxygenProductionPng from '../../../resources/pngs/clouds.png';
import StabilityPng from '../../../resources/pngs/tree-falling.png';
import CanopyPng from '../../../resources/pngs/forest.png';
import BiodiversityPng from '../../../resources/jpgs/biodiversity.jpg';
// Services
import CustomFieldsService from '../../../services/CustomFieldsService';
// Utils
import ProjectsUtil from '../../../utils/ProjectsUtil';
import FieldsUtil from '../../../utils/FieldsUtil';
import RightsUtil from '../../../utils/RightsUtil';

class RequiredFieldsForm extends Component {
    state = {
        requiredFields: null,
        customFields: [],
        treesFields: [],
        greenSpacesFields: [],
        furnituresFields: [],
        formulas: []
    }

    render() {
        const { project, rights } = this.props;
        const { customFields } = this.state;
        let panes = [];

        if (!project?.id || (rights?.requiredFields && RightsUtil.canRead(rights?.trees)))
            panes.push({
                menuItem: i18n.t("Arbres"), render: () => (
                    <Tab.Pane style={{ display: 'flex', overflowY: !isMobileOnly ? 'hidden' : 'scroll', flex: 1 }}>
                        <FieldsManagement
                            project={project} category='Arbre' customFields={customFields} fields={this.state.treesFields} defaultOrder={this.getFieldsDefaultOrder(this.state.treesFields)}
                            categories={[...this.props.defaultFieldCategories.filter(dfc => dfc.category === 'Arbre'), ...(this.props.project.fieldCategories?.filter(fc => fc.category === 'Arbre')) || []]}
                            handleCheckChange={this.handleTreesCheckedChange} handleFieldCategory={this.props.handleFieldCategory} updateCustomFieldsOrder={this.updateCustomFieldsOrder}
                            handleProjectPropertyChange={this.props.handleChange}
                        />
                    </Tab.Pane>
                )
            });
        if (!project?.id || (rights?.requiredFields && RightsUtil.canRead(rights?.greenSpaces)))
            panes.push({
                menuItem: i18n.t("Espaces verts"), render: () => (
                    <Tab.Pane style={{ display: 'flex', overflowY: !isMobileOnly ? 'hidden' : 'scroll', flex: 1 }}>
                        <FieldsManagement
                            project={project} category='Espace vert' customFields={customFields} fields={this.state.greenSpacesFields} defaultOrder={this.getFieldsDefaultOrder(this.state.greenSpacesFields)}
                            categories={[...this.props.defaultFieldCategories.filter(dfc => dfc.category === 'Espace vert'), ...(this.props.project.fieldCategories?.filter(fc => fc.category === 'Espace vert') || [])]}
                            handleCheckChange={this.handleGreenSpacesCheckedChange} handleFieldCategory={this.props.handleFieldCategory} updateCustomFieldsOrder={this.updateCustomFieldsOrder}
                            handleProjectPropertyChange={this.props.handleChange}
                        />
                    </Tab.Pane>
                )
            });
        if (!project?.id || (rights?.requiredFields && RightsUtil.canRead(rights?.furnitures)))
            panes.push({
                menuItem: i18n.t("Mobilier urbain"), render: () => (
                    <Tab.Pane style={{ display: 'flex', overflowY: !isMobileOnly ? 'hidden' : 'scroll', flex: 1 }}>
                        <FieldsManagement
                            project={project} category='Mobilier' customFields={customFields} fields={this.state.furnituresFields} defaultOrder={this.getFieldsDefaultOrder(this.state.furnituresFields)}
                            categories={[...this.props.defaultFieldCategories.filter(dfc => dfc.category === 'Mobilier'), ...(this.props.project.fieldCategories?.filter(fc => fc.category === 'Mobilier') || [])]}
                            handleCheckChange={this.handleFurnituresCheckedChange} handleFieldCategory={this.props.handleFieldCategory} updateCustomFieldsOrder={this.updateCustomFieldsOrder}
                            handleProjectPropertyChange={this.props.handleChange}
                        />
                    </Tab.Pane>
                )
            });
        if (!project?.id || rights?.formulas)
            panes.push({
                menuItem: i18n.t("Champs calculés"), render: () => (
                    <Tab.Pane style={{ display: 'flex', overflowY: 'autp', flex: 1 }}>
                        <FormulasForm
                            formulas={this.state.formulas} project={project}
                            handleCheckChange={this.handleFormulasCheckedChange}
                        />
                    </Tab.Pane>
                )
            });

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'hidden' }}>
                {this.state.requiredFields && (this.state.treesFields.length || this.state.greenSpacesFields.length) &&
                    <Tab panes={panes} style={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column', flex: 1 }} />}
            </div>
        );
    }

    componentDidMount = () => {
        let requiredFields;
        if (this.props.requiredFields?.constructor === ({}).constructor) requiredFields = this.props.requiredFields;
        else if (this.props.requiredFields) requiredFields = JSON.parse(this.props.requiredFields);
        else requiredFields = ProjectsUtil.getRequiredFields();

        const setElementsFields = (projectCustomFields = []) => {
            projectCustomFields = projectCustomFields.filter(cf => this.props.project?.projectCustomFields?.find(pcf => pcf.customFieldId === cf.id));
            const customFields = this.props.customFields.filter(cf => !projectCustomFields.find(pcf => pcf.id === cf.id && pcf.category === cf.category));

            this.setState({
                requiredFields,
                customFields: [...customFields, ...projectCustomFields]
            }, () => {
                this.setState({
                    treesFields: this.getTreesFields(), greenSpacesFields: this.getGreenSpacesFields(), furnituresFields: this.getFurnituresFields(),
                    formulas: [...this.getTreeFormulas(), ...this.getGreenSpaceFormulas()]
                });
            });
        }

        if (this.props.project?.id)
            if (!this.props.projectsCustomFields[this.props.project.id])
                CustomFieldsService.getProjectCustomFields(this.props.project.id).then(projectCustomFields => {
                    this.props.setProjectCustomFields(this.props.project.id, projectCustomFields);
                    setElementsFields(projectCustomFields);
                });
            else setElementsFields(this.props.projectsCustomFields[this.props.project.id]);
        else setElementsFields();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((!prevState.requiredFields && this.state.requiredFields) || (prevProps.project && JSON.stringify(prevProps.project) !== JSON.stringify(this.props.project))) {
            this.setState({
                treesFields: this.getTreesFields(), greenSpacesFields: this.getGreenSpacesFields(), furnituresFields: this.getFurnituresFields(),
                treeFormulas: this.getTreeFormulas(), greenSpaceFormulas: this.getGreenSpaceFormulas()
            });
        }
    }

    getFieldsDefaultOrder = (fields = []) => {
        return fields
            .filter(field => field.isGralityField)
            .reduce((groups, item) => ({
                ...groups,
                [item.fieldCategoryId]: [...(groups[item.fieldCategoryId] || []), item]
            }), {});
    }

    getTreesFields = () => {
        const { project, amenityFormulaType, defaultFieldCategories } = this.props;
        const { requiredFields, customFields } = this.state;
        const trees = requiredFields?.trees;
        const projectCustomFields = project?.projectCustomFields || [];

        return FieldsUtil.getTreeFields(amenityFormulaType, projectCustomFields, customFields, defaultFieldCategories).map(field => {
            const projectCustomField = field.isCustomField ? projectCustomFields.find(pcf => Number(pcf.customFieldId) === Number(field.id)) : null;
            return field.id !== 'gender' && !field.isCustomField ? { ...field, isChecked: trees[field.id] }
                : field.isCustomField ? { ...field, isChecked: projectCustomField ? true : false }
                    : { ...field, isChecked: true, isRequired: true };
        });
    }

    getGreenSpacesFields = () => {
        const { project, defaultFieldCategories } = this.props;
        const { requiredFields, customFields } = this.state;
        const greenSpaces = requiredFields?.greenSpaces;
        const projectCustomFields = project?.projectCustomFields || [];

        return FieldsUtil.getGreenSpaceFields(projectCustomFields, customFields, defaultFieldCategories).map(field => {
            const projectCustomField = field.isCustomField ? projectCustomFields.find(pcf => Number(pcf.customFieldId) === Number(field.id)) : null;
            return field.id !== 'surface' && !field.isCustomField ? { ...field, isChecked: greenSpaces[field.id] }
                : field.isCustomField ? { ...field, isChecked: projectCustomField ? true : false }
                    : { ...field, isChecked: true, isRequired: true };
        });
    }

    getFurnituresFields = () => {
        const { project, defaultFieldCategories } = this.props;
        const { requiredFields, customFields } = this.state;
        const furnitures = requiredFields?.furnitures;
        const projectCustomFields = project?.projectCustomFields || [];

        return FieldsUtil.getFurnitureFields(projectCustomFields, customFields, defaultFieldCategories).map(field => {
            const projectCustomField = field.isCustomField ? projectCustomFields.find(pcf => Number(pcf.customFieldId) === Number(field.id)) : null;
            return !field.isCustomField ? { ...field, isChecked: furnitures[field.id] } : { ...field, isChecked: projectCustomField ? true : false };
        });
    }

    getTreeFormulas = () => {
        const { subscription } = this.props;
        const { requiredFields } = this.state;
        const trees = requiredFields?.trees;

        let formulas = [
            { id: 'stability', label: i18n.t("Stabilité"), category: 'trees', image: StabilityPng, isChecked: trees.stability, infoIcon: i18n.t("Le facteur de stabilité donne une indication sur la résistance au basculement d'un arbre situé dans un massif en fonction de ses dimensions (hauteur arbre/diamètre tronc) : <30 = excellent, 30 à 50 = bon, 50 à 70 = moyen, >70 = mauvais") },
            { id: 'canopy', label: i18n.t("Canopée de l'arbre"), category: 'trees', image: CanopyPng, isChecked: trees.canopy, isDisabled: !subscription.canopyFormula, infoIcon: i18n.t("Surface projetée du houppier") },
            { id: 'carbonStock', label: i18n.t("Stock carbone"), category: 'trees', image: CarbonStockPng, isChecked: trees.carbonStock, isDisabled: !subscription.carbonStockFormula, infoIcon: i18n.t("Quantité de carbone stockée par l'arbre") },
            { id: 'coolingIndicator', label: i18n.t("Rafraîchissement"), category: 'trees', image: CoolingIndicatorPng, isChecked: trees.coolingIndicator, isDisabled: !subscription.coolingFormula, infoIcon: i18n.t("Évaluation du potentiel rafraîchissement assuré par l'arbre") },
            { id: 'oxygenProduction', label: i18n.t("Production d'oxygène"), category: 'trees', image: OxygenProductionPng, isChecked: trees.oxygenProduction, isDisabled: !subscription.carbonStockFormula, infoIcon: i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par l'arbre") },
            { id: 'amenityValue', label: i18n.t("Valeur d'agrément"), category: 'trees', image: AmenityValuePng, isChecked: trees.amenityValue, isDisabled: !subscription.amenityFormula, infoIcon: i18n.t("Calcul de la valeur d'agrément selon formule applicable dans la zone géographique choisie") },
            { id: 'biodiversityIndex', label: i18n.t("Potentiel de biodiversité"), category: 'trees', image: BiodiversityPng, isChecked: trees.biodiversityIndex, infoIcon: i18n.t("Score de biodiversité de l'arbre, compris entre 0 (mauvais) et 1 (très bon)") }
        ];

        return formulas;
    }

    getGreenSpaceFormulas = () => {
        const { subscription } = this.props;
        const { requiredFields } = this.state;
        const greenSpaces = requiredFields?.greenSpaces;
        return [
            { id: 'carbonStock', label: i18n.t("Stock carbone"), category: 'greenSpaces', image: CarbonStockPng, isChecked: greenSpaces.carbonStock, isDisabled: !subscription.carbonStockFormula, infoIcon: i18n.t("Quantité de carbone stockée par les végétaux") },
            { id: 'coolingIndicator', label: i18n.t("Rafraîchissement"), category: 'greenSpaces', image: CoolingIndicatorPng, isChecked: greenSpaces.coolingIndicator, isDisabled: !subscription.coolingFormula, infoIcon: i18n.t("Évaluation du potentiel rafraîchissement assuré par l'espace vert") },
            { id: 'oxygenProduction', label: i18n.t("Production d'oxygène"), category: 'greenSpaces', image: OxygenProductionPng, isChecked: greenSpaces.oxygenProduction, isDisabled: !subscription.carbonStockFormula, infoIcon: i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par l'espace vert") }
        ];
    }

    handleTreesCheckedChange = (fields, isChecked) => {
        let trees = this.state.requiredFields.trees;
        let treesFields = JSON.parse(JSON.stringify(this.state.treesFields));
        fields.forEach(field => {
            field.isChecked = isChecked;
            if (!field.isCustomField) {
                const id = field.id;
                trees = { // Mise à jour des propriétés sélectionnées
                    ...trees,
                    [id]: isChecked
                }

                // Essence
                if (id === 'species' && !isChecked) trees.cultivar = false;
                else if (id === 'cultivar' && isChecked) trees.species = true;

                // Formule du stock carbone
                if (id === 'trunks' && !isChecked) trees.carbonStock = false;
                else if (id === 'carbonStock' && isChecked) trees.trunks = true;

                // Formule de la valeur d'agrément
                if (this.props.amenityFormulaType === 'Wallonie') {
                    if ((id === 'trunks' || id === 'plantationCoefficient' || id === 'situationCoefficient'
                        || id === 'patrimonialCoefficient') && !isChecked) {
                        trees.amenityValue = false;
                    } else if (id === 'amenityValue' && isChecked) {
                        trees.trunks = true;
                        trees.plantationCoefficient = true;
                        trees.situationCoefficient = true;
                        trees.patrimonialCoefficient = true;
                    }
                } else {
                    if ((id === 'trunks' || id === 'plantationCoefficient'
                        || id === 'situationCoefficient' || id === 'healthReview') && !isChecked) {
                        trees.amenityValue = false;
                    } else if (id === 'amenityValue' && isChecked) {
                        trees.trunks = true;
                        trees.plantationCoefficient = true;
                        trees.situationCoefficient = true;
                        trees.healthReview = true;
                    }
                }

                // Formule de l'indicateur de rafraîchissement
                if (id === 'trunks' && !isChecked) trees.coolingIndicator = false;
                else if (id === 'coolingIndicator' && isChecked) {
                    trees.trunks = true;
                    trees.healthReview = true;
                }

                if (['trunks', 'coverType', 'gender', 'situationCoefficient', 'plantationCoefficient'].includes(id) && !isChecked) trees.biodiversityIndex = false;
                else if (id === 'biodiversityIndex' && isChecked) {
                    trees.trunks = true;
                    trees.coverType = true;
                    trees.gender = true;
                    trees.situationCoefficient = true;
                    trees.plantationCoefficient = true;
                }
            }
        });

        // Mise à jour des champs sur base des RF/CF
        treesFields.forEach(treeField => {
            if (!treeField.isCustomField)
                treeField.isChecked = trees[treeField.id] !== undefined ? trees[treeField.id] : isChecked;
            else if (fields.find(f => f.id === treeField.id)) {
                treeField.isChecked = isChecked;
                treeField.fieldCategoryId = treeField.fieldCategoryId;
            }
        });

        this.setState(prevState => ({
            ...prevState,
            requiredFields: {
                ...prevState.requiredFields,
                trees
            },
            treesFields
        }), () => this.props.handleChange('requiredFields', this.state.requiredFields));
    }

    handleGreenSpacesCheckedChange = (fields, isChecked) => {
        let greenSpaces = this.state.requiredFields.greenSpaces;
        let greenSpacesFields = JSON.parse(JSON.stringify(this.state.greenSpacesFields));
        fields.forEach(field => {
            field.isChecked = isChecked;
            if (!field.isCustomField) {
                const id = field.id;
                greenSpaces = { // Mise à jour des propriétés sélectionnées
                    ...greenSpaces,
                    [id]: isChecked
                }

                // Fréquence annuelle
                if (id === 'dominantComposition' && !isChecked) {
                    greenSpaces.detailedComposition = false;
                    greenSpaces.managementClass = false;
                    greenSpaces.annualMaintenanceFrequency = false;
                }
                // Composition détaillée
                if (id === 'detailedComposition' && isChecked) greenSpaces.dominantComposition = true;
                // Classe de gestion
                if (id === 'managementClass' && !isChecked) greenSpaces.annualMaintenanceFrequency = false;
                else if (id === 'managementClass') greenSpaces.dominantComposition = true;
                // Fréquence annuelle d'entretien
                if (id === 'annualMaintenanceFrequency' && isChecked) {
                    greenSpaces.dominantComposition = true;
                    greenSpaces.managementClass = true;
                }

                // Formule du stock carbone
                if ((id === 'surface' || id === 'dominantComposition') && !isChecked) greenSpaces.carbonStock = false;
                else if (id === 'carbonStock' && isChecked) {
                    greenSpaces.surface = true;
                    greenSpaces.dominantComposition = true;
                }
            }
        });

        // Mise à jour des champs sur base des RF/CF
        greenSpacesFields.forEach(greenSpaceField => {
            if (!greenSpaceField.isCustomField)
                greenSpaceField.isChecked = greenSpaces[greenSpaceField.id] !== undefined ? greenSpaces[greenSpaceField.id] : isChecked;
            else if (fields.find(f => f.id === greenSpaceField.id)) {
                greenSpaceField.isChecked = isChecked;
                greenSpaceField.fieldCategoryId = greenSpaceField.fieldCategoryId;
            }
        });

        this.setState(prevState => ({
            ...prevState,
            requiredFields: {
                ...prevState.requiredFields,
                greenSpaces
            },
            greenSpacesFields
        }), () => this.props.handleChange('requiredFields', this.state.requiredFields));
    }

    handleFurnituresCheckedChange = (fields, isChecked) => {
        let furnitures = this.state.requiredFields.furnitures;
        let furnituresFields = JSON.parse(JSON.stringify(this.state.furnituresFields));
        fields.forEach(field => {
            field.isChecked = isChecked;
            if (!field.isCustomField) furnitures[field.id] = isChecked; // Mise à jour des propriétés sélectionnées
        });

        // Mise à jour des champs sur base des RF/CF
        furnituresFields.forEach(furnitureField => {
            if (!furnitureField.isCustomField)
                furnitureField.isChecked = furnitures[furnitureField.id] !== undefined ? furnitures[furnitureField.id] : isChecked;
            else if (fields.find(f => f.id === furnitureField.id)) {
                furnitureField.isChecked = isChecked;
                furnitureField.fieldCategoryId = furnitureField.fieldCategoryId;
            }
        });

        this.setState(prevState => ({
            ...prevState,
            requiredFields: {
                ...prevState.requiredFields,
                furnitures
            },
            furnituresFields
        }), () => this.props.handleChange('requiredFields', this.state.requiredFields));
    }

    handleFormulasCheckedChange = (category, formula, isChecked) => {
        let requiredFields = JSON.parse(JSON.stringify(this.state.requiredFields[category]));
        requiredFields[formula] = isChecked;

        const formulas = JSON.parse(JSON.stringify(this.state.formulas));
        formulas.find(f => f.id === formula && f.category === category).isChecked = isChecked;

        this.setState(prevState => ({
            ...prevState,
            requiredFields: {
                ...prevState.requiredFields,
                [category]: requiredFields
            },
            formulas
        }), () => this.props.handleChange('requiredFields', this.state.requiredFields));
    }

    updateCustomFieldsOrder = (category, customFields = []) => {
        const initialProjectCustomFields = JSON.parse(JSON.stringify((this.props.project?.projectCustomFields || [])));
        let projectCustomFields = JSON.parse(JSON.stringify((initialProjectCustomFields)));
        const isRemoving = customFields.length ? customFields[0].fieldCategoryId === 'general' : true;

        if (isRemoving) projectCustomFields = projectCustomFields.filter(pcf => !customFields.find(cf => cf.id === String(pcf.customFieldId)))
        else projectCustomFields = customFields.map(cf => {
            const customFieldInProps = [...this.props.customFields, ...projectCustomFields].find(cfip => cfip.id === cf.id || String(cfip.id) === cf.id);
            return ({
                customFieldId: cf.id,
                projectId: this.props.project.id,
                fieldCategoryId: cf.fieldCategoryId,
                order: cf.order,
                deletionDate: customFieldInProps?.deletionDate || null,
            });
        });

        const missingCustomFields = this.props.customFields.filter(customField => customField.category !== category && !projectCustomFields.find(pcf => pcf.customFieldId === customField.id));
        const missingProjectCustomFields = initialProjectCustomFields.filter(pcf => missingCustomFields.find(customField => customField.id === pcf.customFieldId || String(customField.id) === pcf.customFieldId));

        projectCustomFields = [...projectCustomFields, ...missingProjectCustomFields];
        projectCustomFields.forEach(pcf => {
            pcf.customFieldId = isNaN(pcf.customFieldId) ? pcf.customFieldId : Number(pcf.customFieldId);
            pcf.fieldCategoryId = isNaN(pcf.fieldCategoryId) ? pcf.fieldCategoryId : Number(pcf.fieldCategoryId);
        });

        // Tri
        projectCustomFields.sort((a, b) => Number(a.fieldCategoryId) - Number(b.fieldCategoryId));
        let order = 0;
        let previousFieldCategoryId = projectCustomFields.length ? projectCustomFields[0].fieldCategoryId : 0;
        projectCustomFields.forEach(pcf => {
            if (pcf.fieldCategoryId !== previousFieldCategoryId) {
                previousFieldCategoryId = pcf.fieldCategoryId;
                order = 0;
            }

            pcf.order = order++;
        });

        this.props.handleChange('projectCustomFields', projectCustomFields);
    }
}

const mapStateToProps = (state) => {
    return {
        customFields: [...state.customFields, ...state.organizationCustomFields || []],
        projectsCustomFields: state.projectsCustomFields,
        defaultFieldCategories: state.defaultFieldCategories,
        rights: state.rights
    };
};

const mapDispatchToProps = {
    setProjectCustomFields
};

export default connect(mapStateToProps, mapDispatchToProps)(RequiredFieldsForm);
import React, { Component } from 'react';
// Composants
import { Menu, Button, Dropdown } from 'semantic-ui-react'
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { setRequest } from '../../actionCreators/appActions';
import { faUserHelmetSafety, faBook, faEdit, faFileAlt, faHistory, faImage, faMapMarkerAlt, faArrowLeft, faArrowRight, faLink } from '@fortawesome/pro-solid-svg-icons';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import RightsUtil from '../../utils/RightsUtil';

class ModalNavbar extends Component {
    state = {
        activeItem: null,
        id: null,
        layer: null
    };

    render() {
        const { project, rights, activeOrganization, projectCollaborators } = this.props;
        const { activeItem, layer } = this.state;
        const properties = layer?.properties;
        const isInProject = projectCollaborators && ProjectsUtil.isUserInProject(projectCollaborators);
        const subscription = project.organization?.subscription;
        const publicFields = project ? ProjectsUtil.getProjectPublicFields(project, projectCollaborators) : ProjectsUtil.getPublicFields();
        const right = layer?.properties.category === 'Arbre' ? 'trees'
            : layer?.properties.category === 'Espace vert' ? 'greenSpaces'
                : layer?.properties.category === 'Mobilier' ? 'furnitures'
                    : layer?.properties.category === 'Repère' ? 'markers'
                        : null;
        const canEdit = this.props.logged && RightsUtil.canWrite(rights[right]);
        const canExport = RightsUtil.canExport(rights[right]) && subscription?.export && activeOrganization?.subscription.export;
        const areFilesAccessible = ProjectsUtil.isUserInProject(this.props.projectCollaborators);
        const areActionsReadable = publicFields.main.actions && RightsUtil.canRead(rights.actions) && layer?.properties.category !== 'Repère';

        return (
            <div className='modalNavbar'>
                <Menu attached='top' tabular className='left'>
                    <Menu.Item
                        name='details' active={activeItem === 'details'}
                        style={{ marginLeft: '3px' }}
                        onClick={() => this.handleClick('Detail', i18n.t("Détails"), 'details', true)}
                    >
                        <FontAwesomeIcon icon={faBook} style={{ marginRight: !isMobileOnly && '10px' }} /> {!isMobileOnly && i18n.t("Détails")}
                    </Menu.Item>
                    {canEdit &&
                        <>
                            <Menu.Item
                                name='editForm' active={activeItem === 'editForm'}
                                onClick={() => this.handleClick('Form', i18n.t("Modification d'un élément"), 'editForm', true)}
                            >
                                <FontAwesomeIcon icon={faEdit} style={{ marginRight: !isMobileOnly && '10px' }} /> {!isMobileOnly && i18n.t("Modification")}
                            </Menu.Item>
                            {!isMobileOnly && layer?.properties.category !== 'Repère' &&
                                <Menu.Item
                                    name='history' active={activeItem === 'history'}
                                    onClick={() => this.handleClick('History', i18n.t("Historique"), 'history', true)}
                                >
                                    <FontAwesomeIcon icon={faHistory} style={{ marginRight: !isMobileOnly && '10px' }} />{i18n.t("Historique")}
                                </Menu.Item>}
                        </>}
                    {publicFields.main.photos &&
                        <Menu.Item
                            name='photosGallery' active={activeItem === 'photosGallery'} id='bYwBN8Y8'
                            onClick={() => this.handleClick('PhotosGallery', i18n.t("Photos"), 'photosGallery')}
                        >
                            <FontAwesomeIcon icon={faImage} style={{ marginRight: !isMobileOnly && '10px' }} /> {!isMobileOnly && i18n.t("Photos")}
                        </Menu.Item>}
                    {areFilesAccessible && subscription?.filesSharing && publicFields.main.files &&
                        <Menu.Item
                            name='elementFilesGallery' active={activeItem === 'elementFilesGallery'} id='qYHyACjC'
                            onClick={() => this.handleClick('ElementFilesGallery', i18n.t("Fichiers"), 'elementFilesGallery')}
                        >
                            <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: !isMobileOnly && '10px' }} /> {!isMobileOnly && i18n.t("Fichiers")}
                        </Menu.Item>}
                    {areActionsReadable &&
                        <Menu.Item
                            name='actionForm' active={activeItem === 'actionForm'} id='2RBnDF6S'
                            onClick={() => this.handleClick('ActionForm', i18n.t("Actions liées à l'élément"), 'actionForm')}
                        >
                            <FontAwesomeIcon icon={faUserHelmetSafety} style={{ marginRight: !isMobileOnly && '10px' }} /> {!isMobileOnly && i18n.t("Actions")}
                        </Menu.Item>}
                    {(isInProject || project.isPublic) &&
                        <Menu.Item
                            name='linkedElements' active={activeItem === 'linkedElements'} id='r5BsDF0S'
                            onClick={() => this.handleClick('LinkedElementList', i18n.t("Éléments liés"), 'linkedElements')}
                        >
                            <FontAwesomeIcon icon={faLink} style={{ marginRight: !isMobileOnly && '10px' }} /> {!isMobileOnly && i18n.t("Éléments liés")}
                        </Menu.Item>}
                </Menu>
                {properties &&
                    <div className='right'>
                        <Button.Group>
                            <Dropdown icon='ellipsis vertical' floating button direction='left' id='OM97o0SR' className='icon' style={{ padding: '8px' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Menu scrolling>
                                        {layer.projectId > 0 &&
                                            <Dropdown.Item icon='clipboard' text={i18n.t("Copier le lien")} onClick={this.handleCopyClick} />}
                                        {canExport &&
                                            <Dropdown.Item
                                                icon='file pdf' id='Lsg0ZkuF' disabled={!this.props.isOnline} text={i18n.t("Exporter la fiche")}
                                                onClick={this.props.showExportPdfForm}
                                            />}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                            {![undefined, 0].includes(layer.projectId) &&
                                <Button
                                    type='button' className='button--secondary' style={{ padding: '8px' }} title={i18n.t("Voir sur la carte")}
                                    onClick={() => this.props.showElement(properties.category, layer, null, { highlight: false, blink: true })}
                                >
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </Button>}
                            {layer.projectId !== 0 &&
                                <>
                                    <Button
                                        type='button' className='button--secondary' style={{ padding: '8px' }} title={i18n.t("Précédent")}
                                        onClick={() => this.handlePreviousClick()}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </Button>
                                    <Button
                                        type='button' className='button--secondary' style={{ padding: '8px' }} title={i18n.t("Suivant")}
                                        onClick={() => this.handleNextClick()}
                                    >
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </Button>
                                </>}
                        </Button.Group>
                    </div>}
            </div>
        );
    }

    componentDidMount = () => {
        const layer = this.props.layer[0];
        const feature = layer?.feature;
        if (feature?.id) this.setState({ id: feature.id, layer: feature });
        this.setState({ activeItem: this.getActiveItem() });
    }

    componentDidUpdate = () => {
        const layer = this.props.layer[0];
        const feature = layer?.feature;
        if (feature?.id !== this.state.id) this.setState({ id: feature.id, layer: feature })
        if (this.getActiveItem() !== this.state.activeItem) this.setState({ activeItem: this.getActiveItem() });
    }

    handleClick = (contentType, title, activeItem, isCategorySpecific = false) => {
        const prefixes = { 'Arbre': 'Tree', 'Espace vert': 'GreenSpace', 'Mobilier': 'Furniture', 'Repère': 'Marker' };

        if (isCategorySpecific) {
            const category = this.state.layer.properties.category;
            contentType = prefixes[category] + contentType;
        }

        this.props.changeModalContentType(contentType, title);
        this.setState({ activeItem });
    }

    handlePreviousClick = () => this.props.previousElement();
    handleNextClick = () => this.props.nextElement();

    handleCopyClick = () => {
        const category = { 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures', 'Repère': 'markers' }[this.props.layer[0].feature.properties.category];
        const EID = this.props.project && this.state.id
            ? window.location.origin + '/projects/' + this.props.project.id + '/' + category + '/' + this.props.layer[0].feature.id
            : null;
        navigator.clipboard.writeText(EID);
        showToast('link_copied');
    }

    getActiveItem = () => {
        switch (this.props.modalContentType) {
            case 'TreeDetail': case 'GreenSpaceDetail': case 'FurnitureDetail': case 'MarkerDetail': return 'details';
            case 'TreeForm': case 'GreenSpaceForm': case 'FurnitureForm': case 'MarkerForm': return 'editForm';
            case 'TreeHistory': case 'GreenSpaceHistory': case 'FurnitureHistory': case 'ActionHistory': return 'history';
            case 'PhotosGallery': return 'photosGallery';
            case 'ElementFilesGallery': return 'elementFilesGallery';
            case 'LinkedElementList': return 'linkedElements';
            case 'ActionForm': return 'actionForm';
            default: return 'details';
        }
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        layer: state.layer,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNavbar);
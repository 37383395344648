import React, { Component } from 'react';
// Composants
import { Button, ButtonGroup, Card, Checkbox, Dimmer, Divider, Dropdown, Form, Grid, Header, Input, Message } from 'semantic-ui-react';
import ContextMenu from './ContextMenu';
// Librairies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faChartTreeMap, faCheck, faEdit, faEye, faEyeSlash, faLayerGroup, faPlus, faTimes, faTimesCircle, faTrash, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faChartTreeMap as faChartTreeMapEmpty } from '@fortawesome/pro-regular-svg-icons';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../locales/i18n';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { contextMenu } from 'react-contexify';
// Redux
import { setProject } from '../../actionCreators/projectsActions';
// Ressources
import OSMPng from '../../resources/pngs/osm.png';
import RoadmapPng from '../../resources/pngs/roadmap.png';
import RoadmapDarkPng from '../../resources/pngs/roadmap-dark.png';
import HybridPng from '../../resources/pngs/hybrid.png';
import PlanPng from '../../resources/pngs/plan.png';
import PlanDarkPng from '../../resources/pngs/plan-dark.png';
import UrbisPng from '../../resources/pngs/urbis.png';
import IGNPlanPng from '../../resources/pngs/ign-plan.png';
import IGNOrthophotoPng from '../../resources/pngs/ign-orthophoto.png';
import SwissMapGeoPng from '../../resources/pngs/swiss-map-geo.png';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import OfflineUtil from '../../utils/OfflineUtil';
import UrlsUtil from '../../utils/UrlsUtil';
import RightsUtil from '../../utils/RightsUtil';
// Services
import ThematicMapsService from '../../services/ThematicMapsService';
import WmsService from '../../services/WmsService';
import AppSettings from '../../AppSettings';

class BaseLayersMenu extends Component {
    state = {
        showBaseLayersList: false,
        expandedOverlays: [],
        searchQuery: '',
        thematicMapToRemove: 0,
        wmsServiceToRemove: 0,
        isLoading: false
    };

    render() {
        const { isToolbarExpanded, isOnline, project, activeOrganization } = this.props;
        const { showBaseLayersList, searchQuery, thematicMapToRemove, wmsServiceToRemove, isLoading } = this.state;
        const baseLayer = this.props.baseLayers?.find(baseLayer => baseLayer.isShown);
        const projectSubscription = project?.organization.subscription;
        const preview = this.getBaseLayerPreview(baseLayer?.name);

        return (
            <>
                <div
                    id='base-layers-menu'
                    style={{
                        flexDirection: 'row', alignItems: 'flex-end', pointerEvents: 'none',
                        left: isToolbarExpanded ? '305px' : '45px', transition: 'left 500ms'
                    }}
                >
                    <div
                        id='active-base-layer' style={{ border: this.getBorderStyle(baseLayer, true), pointerEvents: 'all' }}
                        onMouseOver={() => { if (!isMobile) this.showBaseLayersList(true) }}
                        onMouseOut={() => { if (!isMobile) this.showBaseLayersList(false) }}
                        onClick={() => this.showBaseLayersList(!showBaseLayersList)}
                    >
                        <div>
                            <FontAwesomeIcon icon={faLayerGroup} style={{ transform: 'scale(1.5)' }} />
                        </div>
                        {preview && <img src={preview} alt={baseLayer?.name || 'current layer'} />}
                    </div>
                    {this.props.baseLayers?.length &&
                        <div
                            id='base-layers-list'
                            onMouseOver={() => { if (!isMobile) this.showBaseLayersList(true) }}
                            onMouseOut={() => { if (!isMobile) this.showBaseLayersList(false) }}
                            style={{
                                pointerEvents: showBaseLayersList ? 'all' : 'none', marginLeft: !isMobileOnly && '5px',
                                opacity: showBaseLayersList ? '1' : '0', transition: 'opacity 200ms', position: isMobileOnly && 'absolute', maxWidth: isMobileOnly && '86vw',
                                height: isMobileOnly ? '75vh' : (this.props.project ? '62vh' : 'fit-content'), maxHeight: !isMobileOnly && '500px'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <Header as='h3'>
                                    {i18n.t("Personnaliser l'affichage")}
                                </Header>
                                {isMobile && <Button color='blue' onClick={() => this.showBaseLayersList(false)} style={{ marginLeft: 'auto', padding: '10px 13px' }}><FontAwesomeIcon icon={faTimes} /></Button>}
                            </div>
                            <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', flex: 1, overflow: 'hidden' }}>
                                {(thematicMapToRemove !== 0 || wmsServiceToRemove !== 0) &&
                                    <Dimmer
                                        active style={{ position: 'absolute' }}
                                        onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ thematicMapToRemove: 0, wmsServiceToRemove: 0 }); }}
                                    >
                                        <Grid style={{ height: '100%' }}>
                                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                                <Grid.Column textAlign='center'>
                                                    <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                        <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                                        <Message.Content style={{ marginTop: '10px' }}>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                {thematicMapToRemove !== 0
                                                                    ? i18n.t("Êtes-vous certain de vouloir supprimer cette carte thématique ?")
                                                                    : i18n.t("Êtes-vous certain de vouloir supprimer ce service WMS ?")}
                                                            </div>
                                                            <Button color='grey' disabled={isLoading} onClick={() => this.setState({ thematicMapToRemove: 0, wmsServiceToRemove: 0 })}>
                                                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                            </Button>
                                                            <Button color='red' disabled={!isOnline || isLoading} loading={isLoading} onClick={() => {
                                                                if (thematicMapToRemove !== 0) this.removeThematicMap(thematicMapToRemove);
                                                                else this.removeWmsService(wmsServiceToRemove);
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                            </Button>
                                                        </Message.Content>
                                                    </Message>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Dimmer>}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Header as='h4' style={{ marginTop: 0, marginBottom: isMobileOnly && '5px' }}>{i18n.t("Fonds de carte ({{count}})", { count: this.props.baseLayers?.length || 0 })}</Header>
                                    <div style={{ overflowY: !isMobileOnly && 'overlay', maxWidth: isMobileOnly ? '340px' : '130px', minHeight: isMobileOnly && '90px' }}>
                                        <div className='layer-list' style={{ display: 'flex', flexDirection: 'row', flexWrap: !isMobileOnly && 'wrap', flexShrink: 0, alignItems: isMobileOnly && 'flex-start' }}>
                                            {this.renderBaseLayersList()}
                                        </div>
                                    </div>
                                    {!isMobileOnly && this.props.project && projectSubscription?.thematicMaps && activeOrganization?.subscription.thematicMaps && RightsUtil.canWrite(this.props.rights?.thematicMaps) &&
                                        <Card
                                            id='add-wms' className='transparent'
                                            style={{
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '30px', minHeight: '30px', width: '100%',
                                                marginTop: '10px', boxShadow: 'none', fontSize: '17pt',
                                                border: 'dashed 1px var(--grey-100)', backgroundColor: 'transparent', color: this.props.isDarkTheme ? 'white' : 'black'
                                            }}
                                            onClick={() => this.props.changeModalContentType('WmsServiceForm', i18n.t("Ajout d'un service WMS"))}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Card>}
                                </div>
                                {this.props.project && <>
                                    {!isMobileOnly ? <div className='custom-divider--vertical'></div> : <Divider style={{ marginTop: '10px' }} />}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Header as='h4' style={{ marginTop: 0, marginBottom: isMobileOnly && '5px' }}>{i18n.t("Affichage des données")}</Header>
                                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                                            <Form style={{ marginRight: '10px', flexGrow: 1 }}>
                                                <Input type='text' placeholder={i18n.t("Rechercher...")} style={{ width: '100%' }}
                                                    value={searchQuery} onChange={(_, { value }) => this.setState({ searchQuery: value })}
                                                />
                                            </Form>
                                            {projectSubscription?.thematicMaps && activeOrganization?.subscription.thematicMaps && RightsUtil.canWrite(this.props.rights?.thematicMaps) &&
                                                <Button
                                                    color='green' style={{ margin: '2px 2px 2px 0', padding: '10px 8px' }} disabled={!isOnline}
                                                    onClick={() => this.props.changeModalContentType('ThematicMapForm', i18n.t("Création d'une carte thématique"))}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} style={{ padding: '0 2px' }} />
                                                </Button>}
                                        </div>
                                        {!isMobileOnly && showBaseLayersList &&
                                            <div className='layer-list'>
                                                {this.renderOverlays()}
                                            </div>}
                                    </div>
                                    {isMobileOnly && showBaseLayersList &&
                                        <div className='layer-list'>
                                            {this.renderOverlays()}
                                        </div>}
                                </>}
                            </div>
                        </div>}
                </div>
                <ContextMenu id='context-menu-base-layers-list' onVisibilityChange={isVisible => { if (!isVisible) this.contextMenuElement = null; }} items={this.getContextMenuItems()} />
            </>
        );
    }

    componentDidMount = () => {
        const baseLayerMenu = document.getElementById('base-layers-menu');
        if (!this.props.isOnline) baseLayerMenu.style.bottom = '80px';
    }

    componentDidUpdate = (prevProps) => {
        if ((!prevProps.baseLayers && this.props.baseLayers)
            || (prevProps.baseLayers && this.props.baseLayers && prevProps.baseLayers.length !== this.props.baseLayers.length)
            || (!prevProps.areAdditionalLayersLoaded && this.props.areAdditionalLayersLoaded)) {
            let favoriteTiles = UrlsUtil.getSearchParams(this.props.originalSearch).baseLayer || OfflineUtil.getProjectViewValue(this.props.project?.id, 'favoriteTiles');
            if (!isNaN(favoriteTiles)) favoriteTiles = +favoriteTiles;
            const favoriteBaseLayer = this.props.baseLayers.find(baseLayer => baseLayer.name === favoriteTiles);
            if (favoriteBaseLayer) this.setTiles(favoriteBaseLayer);
        }

        if (!prevProps.map && this.props.map)
            this.props.map.on('click', () => {
                if (this.state.showBaseLayersList)
                    this.showBaseLayersList(false);
            });

        if (prevProps.isOnline !== this.props.isOnline) {
            const baseLayerMenu = document.getElementById('base-layers-menu');
            clearTimeout(this.timeout);
            if (!this.props.isOnline) baseLayerMenu.style.bottom = '80px';
            else this.timeout = setTimeout(() => baseLayerMenu.style.bottom = '50px', 2000);
        }
    }

    renderBaseLayersList = () => {
        const { project, activeOrganization } = this.props;
        const projectSubscription = project?.organization.subscription;

        if (!this.props.baseLayers?.length) return [];
        const blobInfos = AppSettings.getBlobInfos();
        const baseLayers = this.props.baseLayers.map((baseLayer, index) => {
            const wmsService = baseLayer.id && this.props.project.wmsServices?.find(service => service.id === baseLayer.id);
            const preview = wmsService?.preview
                ? `${blobInfos.endpoint}${blobInfos.containers.photos}/${wmsService.preview}`
                : this.getBaseLayerPreview(baseLayer?.name);
            return (
                <div
                    key={index}
                    style={{
                        display: 'flex', flexDirection: 'column', width: '60px', marginLeft: isMobileOnly && '5px',
                        marginRight: isMobileOnly && '5px', overflowWrap: 'break-word'
                    }}
                    onContextMenu={event => { if (baseLayer.id) this.handleContextMenu(event, baseLayer) }}
                >
                    <div
                        className='base-layer-preview' onClick={() => this.setTiles(baseLayer)}
                        style={{
                            marginTop: '10px', marginLeft: !isMobileOnly && 'auto', marginRight: !isMobileOnly && 'auto',
                            border: this.getBorderStyle(baseLayer), backgroundImage: preview ? `url(${preview})` : null,
                            display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14pt', fontWeight: 'bold'
                        }}
                    >
                        {!preview && `${baseLayer?.label?.slice(0, 1).toUpperCase()}${baseLayer?.label?.slice(1, 2).toLowerCase()}`}
                    </div>
                    <div style={{ marginTop: '5px', fontSize: '12px', lineHeight: '15px', textAlign: 'center' }}>{baseLayer.label}</div>
                </div>
            );
        });

        if (this.props.project && isMobileOnly && projectSubscription?.thematicMaps && activeOrganization?.subscription.thematicMaps && RightsUtil.canWrite(this.props.rights?.thematicMaps))
            baseLayers.unshift(
                <Card
                    className='transparent'
                    style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50px', minWidth: '50px', width: '50px',
                        marginTop: '10px', marginLeft: '5px', marginRight: '5px', boxShadow: 'none', fontSize: '17pt',
                        border: 'dashed 1px var(--grey-100)', backgroundColor: 'transparent', color: this.props.isDarkTheme ? 'white' : 'black'
                    }}
                    onClick={() => this.props.changeModalContentType('WmsServiceForm', i18n.t("Ajout d'un service WMS"))}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Card>,
            );

        return baseLayers;
    }

    renderOverlays = () => {
        const { overlays, activeOrganization, isOnline, project } = this.props;
        const { expandedOverlays, searchQuery } = this.state;
        if (!overlays) return [];
        const projectSubscription = project.organization?.subscription;

        return overlays
            .filter(overlay => (
                !searchQuery.trim() ||
                FormattersUtil.getNormalizedString(overlay.label).includes(FormattersUtil.getNormalizedString(searchQuery)) ||
                overlay.children?.some(childOverlay => FormattersUtil.getNormalizedString(childOverlay.label).includes(FormattersUtil.getNormalizedString(searchQuery)))
            ))
            .map((overlay) => {
                const isExpanded = expandedOverlays.includes(overlay.label) ||
                    (searchQuery.trim() && overlay.children?.some(childOverlay => FormattersUtil.getNormalizedString(childOverlay.label).includes(FormattersUtil.getNormalizedString(searchQuery))));
                const isShown = overlay.isShown || (overlay.children?.some(childOverlay => childOverlay.isShown));
                if (!overlay.key) overlay.key = uuidv4();

                return (
                    <div key={overlay.key}>
                        <div id={overlay.headerId} className={`overlay-header${isExpanded ? ' expanded' : ''}`} onClick={() => { if (overlay.children) { this.toggleHeaderExpand(overlay.label) } else { this.props.toggleOverlay(overlay) } }}>
                            {overlay.showToggleLayer &&
                                <FontAwesomeIcon
                                    icon={isShown ? faEye : faEyeSlash} style={{ marginLeft: isShown ? '1.5px' : 0, marginRight: isShown ? '5px' : '4.5px' }}
                                    title={isShown ? i18n.t("Masquer la couche") : i18n.t("Afficher la couche")} onClick={(e) => { e.stopPropagation(); this.props.toggleOverlay(overlay); setTimeout(this.saveOverlaysInCache, 50); }}
                                />}
                            {overlay.showToggleLegend &&
                                <FontAwesomeIcon
                                    icon={overlay.isLegendShown ? faChartTreeMap : faChartTreeMapEmpty} style={{ marginLeft: '1.5px', marginRight: isShown ? '5px' : '4.5px' }}
                                    title={overlay.isLegendShown ? i18n.t("Masquer la légende") : i18n.t("Afficher la légende")} onClick={(e) => { e.stopPropagation(); this.props.toggleLegend(overlay); setTimeout(this.saveOverlaysInCache, 50); }}
                                />}
                            {overlay.icon &&
                                <div style={{ width: '18px', marginLeft: '1.5px', marginRight: '5px', textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={overlay.icon} />
                                </div>}
                            {overlay.label}
                            {overlay.children && <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} style={{ marginLeft: 'auto' }} />}
                            {overlay.id && projectSubscription?.thematicMaps && activeOrganization?.subscription.thematicMaps &&
                                <Dropdown
                                    icon='ellipsis vertical' floating button direction='left' pointing='bottom right' className='icon thematic-map__options'
                                    style={{ marginLeft: 'auto', fontSize: '10px' }}
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Menu scrolling>
                                            <Dropdown.Item icon='edit' text={i18n.t("Éditer")} disabled={!isOnline} onClick={() => this.props.setWmsServiceToEdit(this.props.project.wmsServices.find(wmsService => wmsService.id === overlay.id))} />
                                            <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} disabled={!isOnline} onClick={() => this.setState({ wmsServiceToRemove: overlay.id })} />
                                        </Dropdown.Menu>
                                    </Dropdown.Menu>
                                </Dropdown>}
                        </div>
                        {isExpanded && overlay.children &&
                            <div className='overlay-children'>
                                {overlay.children
                                    .filter(childOverlay => (
                                        !searchQuery.trim() ||
                                        FormattersUtil.getNormalizedString(overlay.label).includes(FormattersUtil.getNormalizedString(searchQuery)) ||
                                        FormattersUtil.getNormalizedString(childOverlay.label).includes(FormattersUtil.getNormalizedString(searchQuery))
                                    ))
                                    .map(childOverlay => {
                                        const isSelected = childOverlay.isShown || (overlay.isShown && overlay.activeChild === (childOverlay.id || childOverlay.label));
                                        if (!childOverlay.key) childOverlay.key = uuidv4();
                                        return (
                                            <>
                                                <div key={childOverlay.key} onClick={() => { this.props.toggleOverlay(childOverlay, true, childOverlay.toggle); setTimeout(this.saveOverlaysInCache, 50); }}>
                                                    {childOverlay.label}
                                                    {isSelected && !childOverlay.toggle && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 'auto' }} />}
                                                    {childOverlay.toggle &&
                                                        <Checkbox
                                                            toggle checked={overlay.activeOptions?.includes(childOverlay.label) ? true : false}
                                                            style={{ marginLeft: 'auto' }}
                                                        />}
                                                    {childOverlay.buttons?.length > 0 &&
                                                        <ButtonGroup className='base-layers-buttons'>
                                                            {childOverlay.buttons.map((b, index) => (
                                                                <Button key={index} title={b.label} color={b.isActive ? 'blue' : undefined} onClick={b.onClick}>
                                                                    <FontAwesomeIcon icon={b.icon} />
                                                                </Button>
                                                            ))}
                                                        </ButtonGroup>}
                                                    {childOverlay.id && projectSubscription?.thematicMaps && activeOrganization?.subscription.thematicMaps &&
                                                        <Dropdown
                                                            icon='ellipsis vertical' floating button direction='left' pointing='bottom right' className='icon thematic-map__options'
                                                            style={{ marginLeft: isSelected ? '5px' : 'auto', fontSize: '10px' }}
                                                        >
                                                            <Dropdown.Menu>
                                                                <Dropdown.Menu scrolling>
                                                                    <Dropdown.Item icon='edit' text={i18n.t("Éditer")} disabled={!isOnline} onClick={() => this.props.setThematicMapToEdit(this.props.project.thematicMaps.find(thematicMap => thematicMap.id === childOverlay.id))} />
                                                                    <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} disabled={!isOnline} onClick={() => this.setState({ thematicMapToRemove: childOverlay.id })} />
                                                                </Dropdown.Menu>
                                                            </Dropdown.Menu>
                                                        </Dropdown>}
                                                </div>
                                                {childOverlay.toggle && <Divider style={{ margin: 0, padding: 0, pointerEvents: 'none' }} />}
                                            </>
                                        );
                                    })}
                            </div>}
                    </div>
                );
            });
    }

    handleContextMenu = (event, wmsService) => {
        this.contextMenuElement = wmsService;
        contextMenu.show({ event, id: 'context-menu-base-layers-list' });
        this.forceUpdate();
    }

    getContextMenuItems = () => {
        const { isOnline } = this.props;
        const wmsService = this.contextMenuElement;
        if (!wmsService) return [];

        return [
            {
                icon: faEdit,
                label: i18n.t("Éditer"),
                isDisabled: () => !isOnline,
                onClick: () => this.props.setWmsServiceToEdit(this.props.project.wmsServices.find(ws => ws.id === wmsService.id))
            },
            {
                icon: faTrashAlt,
                label: i18n.t("Supprimer"),
                isDisabled: () => !isOnline,
                onClick: () => this.setState({ wmsServiceToRemove: wmsService.id })
            }
        ];
    }

    toggleHeaderExpand = (label) => {
        this.setState(prevState => {
            const expandedOverlays = prevState.expandedOverlays.includes(label)
                ? prevState.expandedOverlays.filter(overlay => overlay !== label)
                : [...prevState.expandedOverlays, label];
            return { expandedOverlays };
        });
    }

    showBaseLayersList = (state) => {
        const element = document.getElementById('base-layers-list');

        const showList = () => {
            if (element) element.style.display = state ? 'flex' : 'none';
            setTimeout(() => this.setState({ showBaseLayersList: state }, () => {
                if (element) element.style.opacity = state ? '1' : '0';
            }), 200);
        }

        const hideList = () => {
            if (!this.contextMenuElement) {
                if (element) element.style.opacity = state ? '1' : '0';
                setTimeout(() => this.setState({ showBaseLayersList: state }, () => {
                    if (element) element.style.display = state ? 'flex' : 'none';
                }), 200);
            }
        }

        if (isMobile) state ? showList() : hideList();
        else {
            if (this.showBaseLayersListTimeout) clearTimeout(this.showBaseLayersListTimeout);
            this.showBaseLayersListTimeout = setTimeout(state ? showList : hideList, state ? 150 : 300);
        }
    }

    getBaseLayerPreview = (baseLayerName) => {
        switch (baseLayerName) {
            case 'OSM': return OSMPng;
            case 'Roadmap': return RoadmapPng;
            case 'Roadmap Dark': return RoadmapDarkPng;
            case 'Hybrid': return HybridPng;
            case 'Plan': return this.props.isDarkTheme ? PlanDarkPng : PlanPng;
            case 'UrbIS': return UrbisPng;
            case 'IGN Plan': return IGNPlanPng;
            case 'IGN Orthophoto': return IGNOrthophotoPng;
            case 'Swiss Map Geo': return SwissMapGeoPng;
            default: break;
        }
    }

    getBorderStyle = (baseLayer, isPreview = false) => {
        if (!baseLayer) return 'solid 2px var(--black-100)';

        const { isDarkTheme } = this.props;
        if (baseLayer.isShown && !isPreview) return 'solid 2px var(--primary-100)';
        if ((!baseLayer.isShown && isDarkTheme) || (baseLayer.isShown && ['Hybrid', 'Roadmap Dark', 'IGN Orthophoto'].includes(baseLayer.name))
            || (baseLayer.isShown && isDarkTheme && baseLayer.name === 'Plan'))
            return 'solid 2px var(--white-100)';
        return 'solid 2px var(--black-100)';
    }

    setTiles = (baseLayer) => {
        this.props.showBaseLayer(baseLayer.layer);
        if (this.props.surroundingsLayer)
            this.props.surroundingsLayer.setStyle(this.props.isDarkTheme
                ? StylesUtil.getSurroundingsDarkStyle(baseLayer.name === 'Plan' && { fillOpacity: 0.2, opacity: 0.2 })
                : StylesUtil.getSurroundingsLightStyle(baseLayer.name === 'Plan' && { fillOpacity: 0.2, opacity: 0.2 }));
        OfflineUtil.updateProjectView(this.props.project?.id || 0, 'favoriteTiles', baseLayer.name);
    }

    removeThematicMap = (thematicMapId) => {
        this.setState({ isLoading: true });
        ThematicMapsService.removeThematicMap(thematicMapId).then(() => {
            const { project } = this.props;
            project.thematicMaps = project.thematicMaps.filter(thematicMap => thematicMap.id !== thematicMapId);
            this.props.setProject(project);
            this.setState({ thematicMapToRemove: 0, isLoading: false });
            this.props.removeOverlayFromControlLayer(thematicMapId);
        });
    }

    removeWmsService = (wmsServiceId) => {
        this.setState({ isLoading: true });
        const wmsService = this.props.project.wmsServices.find(wmsService => wmsService.id === wmsServiceId);
        WmsService.removeWmsServices([wmsServiceId]).then(() => {
            const { project } = this.props;
            project.wmsServices = project.wmsServices.filter(wmsService => wmsService.id !== wmsServiceId);
            this.props.setProject(project);
            this.setState({ wmsServiceToRemove: 0, isLoading: false });
            if (wmsService.type === 'baseLayer') this.props.removeBaseLayerFromControlLayer(wmsServiceId);
            else this.props.removeOverlayFromControlLayer(wmsServiceId);
        });
    }

    saveOverlaysInCache = () => {
        if (!this.props.project) return;

        let overlays = [];
        this.props.overlays.forEach(overlay => {
            let overlayCopy = { label: overlay.label };
            if (overlay.hasOwnProperty('activeChild')) overlayCopy['activeChild'] = overlay.activeChild;
            if (overlay.hasOwnProperty('activeOptions')) overlayCopy['activeOptions'] = overlay.activeOptions;
            if (overlay.hasOwnProperty('isShown')) overlayCopy['isShown'] = overlay.isShown;
            if (overlay.hasOwnProperty('isLegendShown')) overlayCopy['isLegendShown'] = overlay.isLegendShown;
            if (overlay.children) overlayCopy['children'] = overlay.children.map(child => {
                let newChild = { label: child.label, parentLabel: child.parentLabel };
                if (child.hasOwnProperty('isShown')) newChild['isShown'] = child.isShown;
                if (child.hasOwnProperty('isLegendShown')) newChild['isLegendShown'] = child.isLegendShown;
                if (child.hasOwnProperty('buttons')) newChild['buttons'] = child.buttons;
                return newChild;
            });
            overlays.push(overlayCopy);
        });

        if (overlays.length) OfflineUtil.updateProjectView(this.props.project.id, 'overlays', overlays);
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isToolbarExpanded: state.isToolbarExpanded,
        isOnline: state.isOnline,
        project: state.project,
        rights: state.rights,
        activeOrganization: state.activeOrganization,
        projectCollaborators: state.projectCollaborators
    };
};

const mapDispatchToProps = {
    setProject
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayersMenu);
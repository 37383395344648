export default class RightsUtil {
    static getUserProjectRights(userProject) {
        return {
            ...this.getRights(),
            ...(userProject?.projectRole || {})
        };
    }

    static getRights(projectRole, readOnly = false) {
        let rights = {
            // Fonctionnalités
            filters: null,
            tables: null,
            statistics: null,
            charts: null,
            timeline: null,
            mapExport: null,
            actions: null,
            projectHistory: null,
            thematicMaps: null,
            // Éléments
            trees: null,
            greenSpaces: null,
            furnitures: null,
            markers: null,
            stations: null,
            backgroundImages: null,
            // Gestion
            parameters: null,
            formulas: null,
            requiredFields: null,
            publicFields: null,
            collaborators: null,
            rights: null
        };

        if (projectRole) rights = JSON.parse(JSON.stringify(projectRole));
        if (readOnly) rights = this.setRightsReadOnly(rights);

        return rights;
    }

    static setRightsReadOnly(rights) {
        let newRights = { ...rights };
        newRights.filters = newRights.filters?.length ? newRights.filters.replace('W', '').replace('I', '') : null;
        newRights.tables = newRights.tables?.length ? newRights.tables.replace('W', '').replace('I', '') : null;
        newRights.statistics = newRights.statistics?.length ? newRights.statistics.replace('W', '').replace('I', '') : null;
        newRights.charts = newRights.charts?.length ? newRights.charts.replace('W', '').replace('I', '') : null;
        newRights.timeline = newRights.timeline?.length ? newRights.timeline.replace('W', '').replace('I', '') : null;
        newRights.actions = newRights.actions?.length ? newRights.actions.replace('W', '').replace('I', '') : null;
        newRights.thematicMaps = newRights.thematicMaps?.length ? newRights.thematicMaps.replace('W', '').replace('I', '') : null;
        newRights.trees = newRights.trees?.length ? newRights.trees.replace('W', '').replace('I', '') : null;
        newRights.greenSpaces = newRights.greenSpaces?.length ? newRights.greenSpaces.replace('W', '').replace('I', '') : null;
        newRights.furnitures = newRights.furnitures?.length ? newRights.furnitures.replace('W', '').replace('I', '') : null;
        newRights.markers = newRights.markers?.length ? newRights.markers.replace('W', '').replace('I', '') : null;
        newRights.stations = newRights.stations?.length ? newRights.stations.replace('W', '').replace('I', '') : null;
        newRights.backgroundImages = newRights.backgroundImages?.length ? newRights.backgroundImages.replace('W', '').replace('I', '') : null;
        return newRights;
    }

    static isReadOnly(rights) {
        return Object.keys(rights)
            .filter(key => !['id', 'icon', 'label', 'userBaseProjects', 'type', 'color', 'baseProjectId', 'deletionDate'].includes(key))
            .find(key => (rights[key] || '').toLowerCase().includes('w')) ? false : true;
    }

    static canRead(right) {
        return right?.includes('R');
    }

    static canWrite(right) {
        return right?.includes('W');
    }

    static canImport(right) {
        return right?.includes('I');
    }

    static canImportGlobally(rights) {
        return this.canImport(rights.trees) || this.canImport(rights.greenSpaces) || this.canImport(rights.furnitures) || this.canImport(rights.stations);
    }

    static canExport(right) {
        return right?.includes('E');
    }

    static canExportGlobally(rights) {
        return this.canExport(rights.trees) || this.canExport(rights.greenSpaces) || this.canExport(rights.furnitures) || this.canExport(rights.stations);
    }

    static isOwner(rights) {
        return rights.type === 'owner';
    }

    static isOwnerOrManager(rights) {
        return ['owner', 'manager'].includes(rights.type);
    }

    static isRestricted(subscription, projectRole) {
        if (!subscription || !projectRole) return true;
        projectRole = { ...projectRole, label: null, userBaseProjects: null, color: null, areas: null, type: null, icon: null };
        return (Object.values(projectRole).some(v => typeof v === 'string' && /[wiWI]/.test(v)) && subscription.name.toLowerCase() === 'free');
    }
}